import { css, cx } from "@emotion/css";
import { Col, Menu, Row } from "antd";
import { useI18n } from "providers/I18n";
import { ReactNode } from "react";

type Props = {
  id?: string;
  event?: string;
  createdBy?: string;
  type?: string | number;
  desc: string;
  icon?: ReactNode;
  color?: string;
  borderColor?: string;
  onOpen: (id?: string) => void;
  onDelete: (id?: string) => void;
};

export const TimelineCard = (props: Props) => {
  const { t } = useI18n();
  const {
    id,
    event,
    createdBy,
    type,
    desc,
    icon,
    color,
    borderColor,
    onOpen,
    onDelete,
  } = props;

  const style = {
    "--color": color || "var(--white)",
    "--borderColor": borderColor || "var(--black)",
  } as React.CSSProperties;

  const items = [
    {
      key: 1,
      label: t("menu.edit"),
      onClick: () => onOpen(id),
    },
    { key: 2, label: t("menu.delete"), onClick: () => !!id && onDelete(id) },
  ];

  return (
    <div style={style} className={styles.card}>
      <div className={styles.container}>
        <Row className={styles.first}>
          <Col xs={{ span: 21, order: 1 }} sm={{ span: 12, order: 1 }}>
            <div className="Body1DarkGreyBold">{event}</div>
            <div className={cx("Body1DarkGrey12Regular", styles.desc)}>{t(desc)}</div>
          </Col>
          <Col xs={{ span: 24, order: 3 }} sm={{ span: 11, order: 2 }}>
            <div className={cx("Body1GreySmall", styles.createdBy)}>
              {`${t("timeline.reportedBy")}: ${createdBy}`}
            </div>
          </Col>
          <Col xs={{ span: 3, order: 2 }} sm={{ span: 1, order: 3 }}>
            <Menu mode="horizontal" items={items} className={styles.menu} />
          </Col>
        </Row>
        <Row className={styles.second}>
          <Col xs={24} sm={12}>
            <div className={styles.typeWrapper}>
              <div className={styles.icon}>{icon}</div>
              <div>{type}</div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const styles = {
  card: css`
    border-radius: 8px;
    width: 100%;
    margin-bottom: 0.5rem;
    overflow: hidden;
    background-color: var(--white);
    box-shadow: 0 0.125rem 0.25rem rgba(33, 37, 41, 0.075);
    border-radius: 10px;
  `,
  container: css`
    display: flex;
    flex-direction: column;
    border: 1px solid var(--light-grey-3);
    border-left: 5px solid var(--borderColor);
    background-color: var(--color);
    padding: 0.5rem 1rem;
    border-radius: 10px;
  `,
  first: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.175);
  `,
  second: css`
    padding: 0.5rem 0;
  `,
  createdBy: css`
    text-align: right;
    @media (max-width: 600px) {
      text-align: left;
    }
  `,
  typeWrapper: css`
    display: flex;
    align-items: flex-end;
  `,
  icon: css`
    margin-right: 0.5rem;
  `,
  menu: css`
    min-width: 0;
    justify-content: center;
    border: none;
    transform: rotate(90deg);
    width: 40px;
    height: 40px;
    align-items: center;
    background-color: transparent;
  `,
  desc: css`
    margin-top: 5px;
  `
};
