import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Table, TableProps } from "antd";
import { fetchAdmin } from "api/admin";
import { AdminUser } from "models/admin";
import { useQuery } from "react-query";
import { Layout } from "../AuthLanding/Layout";
import { css } from "@emotion/css";

export function Admin() {
  const { data } = useQuery(["admin"], fetchAdmin);

  const users = data?.data?.users?.map((u, uidx) => ({
    ...u,
    key: uidx,
  }));

  const columns: TableProps<AdminUser>["columns"] = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "First Name",
      dataIndex: "firstname",
      key: "firstname",
    },
    {
      title: "Last Name",
      dataIndex: "lastname",
      key: "lastname",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Verified",
      dataIndex: "verified",
      key: "verified",
      render: (verified: string) =>
        verified === "true" ? (
          <CheckOutlined style={{ color: "green" }} />
        ) : (
          <CloseOutlined style={{ color: "red" }} />
        ),
    },
    {
      title: "Family Role",
      dataIndex: ["familyRole", "role"], // Nested data access
      key: "familyRole",
      render: (role: string) => role || "N/A", // Display "N/A" if role is null or undefined
    },
    {
      title: "Health Events",
      dataIndex: "healthEventCount", // Use the directly provided healthEventCount
      key: "healthEvents",
      render: (count: number) => count || 0, // Display the count of health events
    },
  ];

  return (
    <Layout>
      <Table columns={columns} dataSource={users} className={styles.root} />
    </Layout>
  );
}

const styles = {
  root: css`
    width: 100%;
  `,
};
