import { StyleSheet } from "@react-pdf/renderer";
import { Table, TR, TH, TD } from "@ag-media/react-pdf-table";
import dayjs from "dayjs";

type Props<T> = {
  headers: string[];
  data: (T & {
    eventTime: string;
  })[];
  columns: (keyof T)[];
  t: (key: string) => string;
};

function ReportTable<T extends Record<string, any>>(props: Props<T>) {
  const { headers, data, columns, t } = props;

  return (
    <Table style={styles.table} tdStyle={styles.cell}>
      <TH fixed>
        <TD style={styles.header}>{t("healthEvent.eventTime")}</TD>
        {headers?.map((h, hidx) => (
          <TD key={hidx} style={styles.header}>
            {t(h)}
          </TD>
        ))}
      </TH>
      {!!data?.length ? (
        data?.map((d, didx) => (
          <TR key={didx} wrap={false}>
            <TD>
              {!!d?.eventTime
                ? `${dayjs(d?.eventTime)?.format("HH:mm")} ${dayjs(
                    d?.eventTime
                  )?.format("DD/MM")}`
                : "-"}
            </TD>
            {columns?.map((c, cidx) => (
              <TD key={cidx}>{!!d?.[c] ? d?.[c] : "-"}</TD>
            ))}
          </TR>
        ))
      ) : (
        <TR wrap={false}>
          <TD>-</TD>
          {headers?.map((_, cidx) => (
            <TD key={cidx}>-</TD>
          ))}
        </TR>
      )}
    </Table>
  );
}

const styles = StyleSheet.create({
  table: {
    padding: "10px 0",
    border: "none",
  },
  header: {
    borderTop: "none",
    borderRight: "1px solid white",
    color: "#212529",
    backgroundColor: "#0d6efd20",
    width: 100,
  },
  cell: {
    padding: "4px 8px",
    borderRight: "1px solid white",
    fontSize: 8,
    color: "#212529",
    backgroundColor: "#eeeeee",
  },
});

export default ReportTable;
