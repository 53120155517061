import { QueryClientProvider, QueryClient } from "react-query";
import {
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
} from "react-router-dom";

import Main from "routes/Main";
import Login from "routes/Auth/Login";
import ErrorBoundary from "components/ErrorBoundary";
import Register from "routes/Auth/Register";
import { Verify } from "routes/Auth/Register/Verify";
import { I18nProvider } from "providers/I18n";

const router = createBrowserRouter([{ path: "*", Component: Root }]);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

function Root() {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <I18nProvider>
          <Routes>
            <Route path="/auth/verify" element={<Verify />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/*" element={<Main />} />
          </Routes>
        </I18nProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default function App() {
  return <RouterProvider router={router} />;
}
