import { Row, Col } from "antd";
import { css, cx } from "@emotion/css";
import { Card } from "components/Card";

import Search from "assets/search.png";
import Pharmacy from "assets/online-pharmacy.png";
import Consultation from "assets/consultation.png";
import Details from "assets/details-info.png";
import Emergency from "assets/emergency-care.png";
import Tracking from "assets/tracking.png";
import { useI18n } from "providers/I18n";

const servicesContent = (t: (key: string) => string) => [
  {
    img: Search,
    title: t("landingPage.advancedSearch.title"),
    body: t("landingPage.advancedSearch.description"),
    alt: "search",
  },
  {
    img: Pharmacy,
    title: t("landingPage.quickAdd.title"),
    body: t("landingPage.quickAdd.description"),
    alt: "pharmacy",
  },
  {
    img: Consultation,
    title: t("landingPage.doctorsDir.title"),
    body: t("landingPage.doctorsDir.description"),
    alt: "consultation",
  },
  {
    img: Details,
    title: t("landingPage.familyRoles.title"),
    body: t("landingPage.familyRoles.description"),
    alt: "details",
  },
  {
    img: Emergency,
    title: t("landingPage.reports.title"),
    body: t("landingPage.reports.description"),
    alt: "emergency",
  },
  {
    img: Tracking,
    title: t("landingPage.timeline.title"),
    body: t("landingPage.timeline.description"),
    alt: "tracking",
  },
];

const ServicesSection = () => {
  const { t } = useI18n();
  return (
    <div className={styles.container}>
      <Row align="middle" justify="center" className={styles.rows}>
        <Col className={cx("HeadlineH2DarkGreyBold", styles.title)}>
          {t("landingPage.ourService.title")}
        </Col>
        <Col className={cx("HeadlineH5DarkGreyRegular", styles.body)}>
          {t("landingPage.ourService.description")}
        </Col>
      </Row>
      <Row
        align="middle"
        justify="center"
        gutter={[16, 16]}
        className={styles.cardRow}
      >
        {servicesContent(t)?.map((s, sidx) => (
          <Col
            xs={24}
            sm={12}
            className={cx("gutter-row", styles.col)}
            key={sidx}
          >
            <Card img={s?.img} title={s?.title} body={s?.body} alt={s?.alt} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ServicesSection;

const styles = {
  container: css`
    margin-top: 140px;
  `,
  rows: css`
    display: flex;
    flex-direction: column;
    padding: 1.5rem calc(1.5rem * 0.5);
    @media (min-width: 1925px) {
      padding: 1.5rem 12rem;
    }
    @media (min-width: 2232px) {
      padding: 1.5rem 20rem;
    }
    @media (min-width: 2501px) {
      padding: 1.5rem 26rem;
    }
    @media (min-width: 2693px) {
      padding: 1.5rem 32rem;
    }
    @media (min-width: 2932px) {
      padding: 1.5rem 38rem;
    }
    @media (min-width: 3384px) {
      padding: 1.5rem 50rem;
    }
    @media (min-width: 3672px) {
      padding: 1.5rem 58rem;
    }
  `,
  title: css`
    margin-bottom: 1.5rem;
  `,
  body: css`
    margin-bottom: 20px;
    text-align: center;
  `,
  cardRow: css`
    @media (min-width: 1925px) {
      padding: 0 12rem;
    }
    @media (min-width: 2232px) {
      padding: 0 20rem;
    }
    @media (min-width: 2501px) {
      padding: 0 26rem;
    }
    @media (min-width: 2693px) {
      padding: 0 32rem;
    }
    @media (min-width: 2932px) {
      padding: 0 38rem;
    }
    @media (min-width: 3384px) {
      padding: 0 50rem;
    }
    @media (min-width: 3672px) {
      padding: 0 58rem;
    }
  `,
  col: css`
    max-width: 400px;
    min-width: 280px;
  `,
};
