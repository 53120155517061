import { ReactNode } from "react";
import { Button, Modal } from "antd";
import { useI18n } from "providers/I18n";

export type ModalInfo =
  | {
      type?: "primary" | "dashed" | "link" | "text" | "default";
      danger?: boolean;
      title?: string;
      description?: ReactNode;
      cancelLabel?: string;
      onSubmit?: (e?: any) => void;
      submitLabel?: string;
      isSubmitDisabled?: boolean;
    }
  | undefined;

type Props = ModalInfo & {
  open: boolean;
  onClose: VoidFunction;
  isLoading?: boolean;
};

const App = (props: Props) => {
  const { t } = useI18n();
  const {
    type = "primary",
    danger = false,
    title,
    description,
    cancelLabel = "cancel",
    onSubmit,
    submitLabel = "submit",
    isSubmitDisabled,
    open,
    onClose,
    isLoading,
  } = props;

  const handleOk = () => {
    onClose();
    !!onSubmit && onSubmit();
  };

  return (
    <Modal
      open={open}
      title={title}
      onOk={handleOk}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          {t(`button.${cancelLabel}`)}
        </Button>,
        <Button
          key="submit"
          type={type}
          danger={danger}
          loading={isLoading}
          onClick={handleOk}
          disabled={isSubmitDisabled}
        >
          {t(`button.${submitLabel}`)}
        </Button>,
      ]}
    >
      <p>{description}</p>
    </Modal>
  );
};

export default App;
