import React from "react";
import { Button, Tooltip } from "antd";
import { css } from "@emotion/css";
import { AuthHeader } from "../AuthHeader";
import CreateHealthEvent from "../HealthEvent/CreateHealthEvent";
import { useFamilyCachedData, useMetricsCachedData } from "../utils";
import { Footer } from "components/Page";

export const Layout = ({ children }: { children: JSX.Element }) => {
  const { family } = useFamilyCachedData();
  const { metrics, metricsUpdatedAt } = useMetricsCachedData();
  const [open, setOpen] = React.useState<boolean>(false);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const hasChildren = family?.members?.some((m) => m?.familyRole === "child");

  const shouldAddHealthEvent = window.location.pathname !== "/admin";

  return (
    <div className={styles.container}>
      <AuthHeader />
      <div className={styles.layout}>{children}</div>
      {shouldAddHealthEvent && (
        <Tooltip
          title={
            !hasChildren
              ? "You need to add children first in order to add a health event."
              : ""
          }
        >
          <Button
            type="primary"
            className={styles.floatingButton}
            onClick={onOpen}
            disabled={!hasChildren}
          >
            +
          </Button>
        </Tooltip>
      )}
      <CreateHealthEvent
        open={open}
        onClose={onClose}
        members={family?.members ?? []}
        metrics={metrics ?? []}
        metricsUpdatedAt={metricsUpdatedAt}
      />
      <Footer />
    </div>
  );
};

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
  `,
  layout: css`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 calc(30rem * 0.5);
    padding-top: 2rem;
    max-width: 100%;
    @media (max-width: 1280px) {
      padding: 0 calc(8rem * 0.5);
    }
    @media (max-width: 768px) {
      padding: 0 calc(5rem * 0.5);
    }
    @media (max-width: 600px) {
      padding: 0 calc(1.5rem * 0.5);
    }
  `,
  floatingButton: css`
    border-radius: 50%;
    height: 50px;
    width: 50px;
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    font-size: 20px;
    font-weight: 700;
    padding-top: 2px;
    @media (max-width: 768px) {
      bottom: 1rem; 
    }
  `,
};
