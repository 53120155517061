import React from "react";
import { Layout } from "../AuthLanding/Layout";
import { css, cx } from "@emotion/css";
import { Col, Row, Select, message, DatePicker, Tag, Button } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/el";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import {
  faMicroscope,
  faUserDoctor,
  faPills,
  faLaptopMedical,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BlobProvider } from "@react-pdf/renderer";
import { PrinterOutlined } from "@ant-design/icons";

import {
  deleteHealthEvent,
  fetchHealthEvents,
  updateHealthEvent,
} from "api/healthEvent";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TimelineCard } from "./TimelineCard";
import ConfirmationModal, { ModalInfo } from "components/ConfirmationModal";
import { MetricsState } from "models/healthEvent";
import CreateHealthEvent from "../HealthEvent/CreateHealthEvent";
import {
  useFamilyCachedData,
  useMetricsCachedData,
  useUserCachedData,
} from "../utils";
import { NoData } from "components/NoData";
import { ReactComponent as Empty } from "assets/no-data.svg";
import { useI18n } from "providers/I18n";
import { NoUndefinedRangeValueType } from "rc-picker/lib/PickerInput/RangePicker";
import { Filters } from "components/Filters";
import { ExportDocument } from "components/ExportDocument";

const { RangePicker } = DatePicker;

const eventTypeOptions = (t: (key: string) => string) =>
  [
    { label: t("healthEvent.healthMetric.title"), value: "includeMetrics" },
    {
      label: t("healthEvent.medicationIntake.title"),
      value: "includeMedicationIntakes",
    },
    {
      label: t("healthEvent.symptomOccurrence.title"),
      value: "includeSymptomOccurrences",
    },
    {
      label: t("healthEvent.doctorVisit.title"),
      value: "includeDoctorVisits",
    },
  ] as {
    label: string;
    value: string;
  }[];

export const Timeline = () => {
  const { t } = useI18n();
  const queryClient = useQueryClient();
  const [messageApi, contextHolder] = message.useMessage();
  const { userData } = useUserCachedData();
  const { language } = userData ?? {};
  const { family, familyUpdatedAt } = useFamilyCachedData();
  const { metrics } = useMetricsCachedData();
  const [state, setState] = React.useState<string>();
  const [dateRange, setDateRange] =
    React.useState<NoUndefinedRangeValueType<dayjs.Dayjs>>();
  const [eventTypeFilter, setEventTypeFilter] = React.useState<string[]>([]);
  const [event, setEvent] = React.useState<MetricsState | undefined>();
  const [open, setOpen] = React.useState<boolean>(false);
  const [error, setError] = React.useState<any>();
  const [modalInfo, setModalInfo] = React.useState<ModalInfo>(undefined);

  const ps = new URLSearchParams();
  !!state && ps.set("createdForId", state);
  !!dateRange?.[0] && ps.set("startDate", dateRange[0].format("YYYY-MM-DD"));
  !!dateRange?.[1] && ps.set("endDate", dateRange[1].format("YYYY-MM-DD"));
  ps.append(
    "includeMetrics",
    String(eventTypeFilter?.includes("includeMetrics"))
  );
  ps.append(
    "includeMedicationIntakes",
    String(eventTypeFilter?.includes("includeMedicationIntakes"))
  );
  ps.append(
    "includeSymptomOccurrences",
    String(eventTypeFilter?.includes("includeSymptomOccurrences"))
  );
  ps.append(
    "includeDoctorVisits",
    String(eventTypeFilter?.includes("includeDoctorVisits"))
  );

  const { data } = useQuery(
    ["health-events", ps.toString()],
    () => fetchHealthEvents(ps.toString()),
    {
      enabled: !!state,
    }
  );
  const { data: healthEvents } = data ?? {};

  const { mutate: _updateHealthEvent } = useMutation(updateHealthEvent, {
    onSuccess: (r) => {
      queryClient.invalidateQueries({ queryKey: ["health-events"] });
      onClose();
      messageApi.open({
        type: "success",
        content: r?.message,
      });
    },
    onError: (err: Error) => {
      const _err = JSON.parse(err.message);
      setError(_err);
      messageApi.open({
        type: "error",
        content: _err?.error,
      });
    },
  });

  const { mutate: _deleteHealthEvent, isLoading: isDeleting } = useMutation(
    deleteHealthEvent,
    {
      onSuccess: (r) => {
        setModalInfo(undefined);
        queryClient.invalidateQueries({ queryKey: ["health-events"] });
        messageApi.open({
          type: "success",
          content: r?.message,
        });
      },
      onError: (err: Error) => {
        setModalInfo(undefined);
        const _err = JSON.parse(err.message);
        setError(_err);
        messageApi.open({
          type: "error",
          content: _err?.error,
        });
      },
    }
  );

  const children = family?.members
    ?.filter((f) => f?.familyRole === "child")
    ?.map((c) => ({ label: c?.firstname, value: c?.id }));

  const onSelectChange = (value: string) => {
    setState(value);
  };

  const update = (event: Partial<MetricsState>) => _updateHealthEvent(event);

  React.useEffect(() => {
    setState(children?.[0]?.value);
    setEventTypeFilter(eventTypeOptions(t)?.map((o) => o?.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [familyUpdatedAt]);

  const onDelete = (id?: string) => {
    setModalInfo({
      title: "Delete Health Event",
      type: "primary",
      danger: true,
      description: "Are you sure you want to delete this Health Event?",
      submitLabel: "delete",
      isSubmitDisabled: isDeleting,
      cancelLabel: "cancel",
      onSubmit: async () => await _deleteHealthEvent(id || ""),
    });
  };

  const onOpen = (id?: string) => {
    setOpen(true);
    !!id &&
      setEvent(() =>
        Object.values(healthEvents ?? {})
          ?.reduce((acc, evt) => [...acc, ...evt])
          ?.find((e) => e?.id === id)
      );
  };
  const onClose = () => {
    setOpen(false);
    setEvent(undefined);
    setError(undefined);
  };

  const onDateRangeChange = (
    dates: NoUndefinedRangeValueType<dayjs.Dayjs>,
    _: [string, string]
  ) => setDateRange(dates);

  const onEventTypeChange = (value: string[]) => {
    setEventTypeFilter(value);
  };

  const onEventTagDelete = (
    e: React.MouseEvent<HTMLElement>,
    eventType: string
  ) => {
    e.preventDefault();
    setEventTypeFilter((s) => {
      const _s = [...s];
      return _s.filter((k) => k !== eventType);
    });
  };

  const onDateTagDelete = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setDateRange(undefined);
  };

  const isModalOpen = Boolean(modalInfo);
  const hasNoEvent = (he: MetricsState) =>
    !he?.metrics?.length &&
    !he?.medicationIntakes?.length &&
    !he?.symptomOccurrences?.length &&
    !he?.doctorVisits?.length;

  return (
    <>
      {contextHolder}
      <Layout>
        <>
          <div className={styles.container}>
            <div className={styles.title}>
              <div className="HeadlineH2DarkGreyBold">
                {t("timeline.title")}
              </div>
            </div>
            <Row className={styles.child} gutter={[0, 8]}>
              <Select
                options={children}
                value={state}
                onChange={onSelectChange}
                className={styles.select}
                size="large"
                placeholder="Child"
              />
              <div className={styles.actions}>
                <Filters
                  children={
                    <>
                      <div className={styles.filter}>
                        <div
                          className={cx(
                            "Body1DarkGreySmall",
                            styles.inputLabel
                          )}
                        >
                          {t("healthEvent.dateRange.title")}
                        </div>
                        <RangePicker
                          onChange={onDateRangeChange}
                          value={dateRange}
                          placeholder={[
                            t("healthEvent.dateRange.startDate"),
                            t("healthEvent.dateRange.endDate"),
                          ]}
                        />
                      </div>
                      <div className={styles.filter}>
                        <div
                          className={cx(
                            "Body1DarkGreySmall",
                            styles.inputLabel
                          )}
                        >
                          {t("healthEvent.eventType")}
                        </div>
                        <Select
                          mode="multiple"
                          size="middle"
                          placeholder={t("healthEvent.selectEventType")}
                          value={eventTypeFilter}
                          onChange={onEventTypeChange}
                          className={styles.eventTypeFilter}
                          options={eventTypeOptions(t)}
                        />
                      </div>
                    </>
                  }
                />
                <Button size="large">
                  <BlobProvider
                    document={
                      <ExportDocument
                        data={data}
                        child={state}
                        family={family}
                        dateRange={dateRange}
                        t={t}
                        language={language ?? "en"}
                      />
                    }
                  >
                    {({ url }) => (
                      <a
                        href={url ?? "#"}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => {
                          if (!url) {
                            e.preventDefault();
                            console.error("Failed to generate PDF");
                          }
                        }}
                      >
                        {/* {t("generateReport")} */}
                        <PrinterOutlined />
                      </a>
                    )}
                  </BlobProvider>
                </Button>
              </div>
            </Row>
            <Row className={styles.tagsRow} gutter={[0, 8]}>
              {!!dateRange && (
                <Tag closeIcon onClose={onDateTagDelete} className={styles.tag}>
                  {`${dateRange?.[0]?.format(
                    "YYYY-MM-DD"
                  )} - ${dateRange?.[1]?.format("YYYY-MM-DD")}`}
                </Tag>
              )}
              {(eventTypeFilter as string[])?.map((e, eidx) => (
                <Tag
                  key={eidx}
                  closeIcon
                  onClose={(evt) => onEventTagDelete(evt, e)}
                  className={styles.tag}
                >
                  {`${t(`healthEvent.${e}`)}`}
                </Tag>
              ))}
            </Row>
            <div className={styles.timeline}>
              {!!Object.keys(healthEvents ?? {})?.length ? (
                Object.keys(healthEvents ?? {})?.map((k, kidx) => (
                  <React.Fragment key={kidx}>
                    <Row className={styles.eventTime} gutter={20}>
                      <Col xs={5} sm={4} className={styles.date}>
                        {dayjs(k)
                          .locale(language ?? "en")
                          .format("D MMMM YYYY")}
                      </Col>
                    </Row>
                    <Row gutter={[24, 8]}>
                      {healthEvents?.[k]?.map((he, heidx) => (
                        <React.Fragment key={heidx}>
                          <Col xs={6} sm={4} className={styles.timeWrapper}>
                            <div
                              className={cx("Body1DarkGrey12Bold", styles.time)}
                            >
                              <FontAwesomeIcon
                                icon={faClock}
                                size={"xs"}
                                className={styles.icon}
                              />
                              {dayjs(he?.eventTime)
                                .locale(language ?? "en")
                                .format("HH:mm")}
                            </div>
                          </Col>
                          <Col xs={18} sm={20}>
                            {!!he?.metrics?.length &&
                              he.metrics.map((m, midx) => (
                                <TimelineCard
                                  id={he?.id}
                                  event={`${m?.typeShortTitle} - ${m?.typeTitle}`}
                                  createdBy={he?.createdBy}
                                  type={`${m?.value} ${m?.unitTitle}`}
                                  desc="healthEvent.healthMetric.title"
                                  borderColor="var(--light-blue)"
                                  color="var(--opacity-light-blue)"
                                  key={midx}
                                  icon={<FontAwesomeIcon icon={faMicroscope} />}
                                  onOpen={() => onOpen(he?.id)}
                                  onDelete={() => onDelete(he?.id)}
                                />
                              ))}
                            {!!he?.medicationIntakes?.length &&
                              he.medicationIntakes.map((m, midx) => (
                                <TimelineCard
                                  id={he?.id}
                                  event={m?.medicineName}
                                  createdBy={he?.createdBy}
                                  type={`${m?.dosage} ${m?.dosageUnitTitle}`}
                                  desc="healthEvent.medicationIntake.title"
                                  borderColor="var(--light-green)"
                                  color="var(--opacity-light-green)"
                                  key={midx}
                                  icon={<FontAwesomeIcon icon={faPills} />}
                                  onOpen={() => onOpen(he?.id)}
                                  onDelete={() => onDelete(he?.id)}
                                />
                              ))}
                            {!!he?.symptomOccurrences?.length &&
                              he.symptomOccurrences.map((m, midx) => (
                                <TimelineCard
                                  id={he?.id}
                                  event={m?.type}
                                  createdBy={he?.createdBy}
                                  type={t(
                                    `healthEvent.symptomOccurrence.severity.${m?.severity}`
                                  )}
                                  desc="healthEvent.symptomOccurrence.symptom"
                                  borderColor="var(--orange)"
                                  color="var(--opacity-orange)"
                                  key={midx}
                                  icon={
                                    <FontAwesomeIcon icon={faLaptopMedical} />
                                  }
                                  onOpen={() => onOpen(he?.id)}
                                  onDelete={() => onDelete(he?.id)}
                                />
                              ))}
                            {!!he?.doctorVisits?.length &&
                              he.doctorVisits.map((m, midx) => (
                                <TimelineCard
                                  id={he?.id}
                                  event={m?.doctorName}
                                  createdBy={he?.createdBy}
                                  type={m?.diagnosis}
                                  desc="healthEvent.doctorVisit.title"
                                  borderColor="var(--dark-grey)"
                                  color="var(--opacity-dark-grey)"
                                  key={midx}
                                  icon={<FontAwesomeIcon icon={faUserDoctor} />}
                                  onOpen={() => onOpen(he?.id)}
                                  onDelete={() => onDelete(he?.id)}
                                />
                              ))}
                            {hasNoEvent(he) && (
                              <TimelineCard
                                id={he?.id}
                                createdBy={he?.createdBy}
                                borderColor="var(--dark-grey)"
                                color="var(--white)"
                                onOpen={() => onOpen(he?.id)}
                                onDelete={() => onDelete(he?.id)}
                                desc=""
                              />
                            )}
                          </Col>
                        </React.Fragment>
                      ))}
                    </Row>
                  </React.Fragment>
                ))
              ) : (
                <NoData title={t("healthEvent.empty")} icon={<Empty />} />
              )}
            </div>
          </div>
        </>
      </Layout>
      <CreateHealthEvent
        open={open}
        onClose={onClose}
        event={event}
        members={family?.members ?? []}
        metrics={metrics ?? []}
        updateEvent={update}
        updateError={error}
      />
      <ConfirmationModal
        open={isModalOpen}
        onClose={() => setModalInfo(undefined)}
        {...modalInfo}
      />
    </>
  );
};

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    width: 100%;
    @media (max-width: 768px) {
      margin-top: 4rem;
    }
  `,
  title: css`
    display: flex;
    margin-bottom: 1.5rem;
  `,
  child: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  select: css`
    min-width: 100%;
    @media (min-width: 376px) {
      min-width: 286px;
      &.ant-select-single.ant-select-lg > div > span {
        font-size: 18px;
      }
    }
  `,
  timeline: css`
    margin: 1rem 0;
  `,
  eventTime: css`
    margin-top: 2rem;
    padding: 0.75rem;
  `,
  date: css`
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--grey);
    text-align: right;
  `,
  timeWrapper: css`
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding-top: 1rem;
  `,
  time: css`
    background-color: var(--light-grey-2);
    width: fit-content;
    padding: 0.25rem 0.5rem;
    border-radius: 8px;
  `,
  icon: css`
    margin-right: 0.25rem;
  `,
  filter: css`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    :not(:last-child) {
      margin-right: 1rem;
    }
  `,
  eventTypeFilter: css`
    width: 240px;
  `,
  inputLabel: css`
    margin-bottom: 0.5rem;
    margin-left: 0.25rem;
  `,
  tagsRow: css`
    margin-top: 1rem;
  `,
  tag: css`
    font-size: 0.9em;
    padding: 2px 8px;
    &.ant-tag .ant-tag-close-icon {
      margin-inline-start: 8px;
    }
  `,
  actions: css`
    display: flex;
    align-items: center;
    grid-column-gap: 0.5rem;
  `,
};
