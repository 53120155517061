import { useAuth } from "providers/A12n";
import { Navigate } from "react-router-dom";

export const AuthorizedRoute = ({ children }: { children: JSX.Element }) => {
  const { user } = useAuth();

  if (!user?.decoded) {
    return <Navigate to="/" />;
  }

  return children;
};
