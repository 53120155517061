import React from "react";
import { StyleSheet, Text, View } from "@react-pdf/renderer";

type Row = {
  title: string;
  Cell: React.ReactNode;
};

type Props = {
  rows: Row[];
};

const ReportTableHor = (props: Props) => {
  const { rows } = props;

  return (
    <View style={styles.table}>
      <View style={styles.container}>
        {rows.map((r, ridx) => (
          <Text key={ridx} style={{ ...styles.header, borderLeft: "none" }}>
            {r?.title}
          </Text>
        ))}
      </View>
      <View style={styles.container}>
        {rows?.map((r, ridx) => (
          <Text key={ridx} style={styles.value}>
            {r?.Cell}
          </Text>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  table: {
    display: "flex",
    flexDirection: "row",
    padding: "10px 0",
    fontSize: 8,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    border: "none",
  },
  header: {
    fontWeight: "bold",
    padding: "4px 8px",
    borderBottom: "1px solid white",
    color: "#212529",
    backgroundColor: "#0d6efd20",
  },
  value: {
    width: "128px",
    padding: "4px 8px",
    borderBottom: "1px solid white",
    color: "#212529",
    backgroundColor: "#eeeeee",
  },
});

export default ReportTableHor;
