import { css, cx } from "@emotion/css";
import { Button, Col, Input, Row, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { CloseOutlined } from "@ant-design/icons";
import { MetricsState, SymptomState } from "models/healthEvent";
import { useI18n } from "providers/I18n";

const options = (t: (key: string) => string) => [
  {
    label: t("healthEvent.symptomOccurrence.severity.mild"),
    value: "mild",
  },
  {
    label: t("healthEvent.symptomOccurrence.severity.moderate"),
    value: "moderate",
  },
  {
    label: t("healthEvent.symptomOccurrence.severity.severe"),
    value: "severe",
  },
  {
    label: t("healthEvent.symptomOccurrence.severity.verySevere"),
    value: "verySevere",
  },
  {
    label: t("healthEvent.symptomOccurrence.severity.critical"),
    value: "critical",
  },
];

type Props = {
  state: MetricsState;
  onChange: (
    path: string,
    value: string | SymptomState | SymptomState[]
  ) => void;
};

export const SymptomOccurrence = (props: Props) => {
  const { t } = useI18n();
  const { state, onChange: _onChange } = props;

  const onChange = (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    idx: number
  ) => {
    const { name, value } = evt?.target ?? {};
    _onChange(`symptomOccurrences.${idx}.${name}`, value);
  };

  const onSelectChange = (
    value: string,
    _:
      | {
          label: string;
          value: string;
        }
      | {
          label: string;
          value: string;
        }[],
    name: string,
    idx: number
  ) => {
    _onChange(`symptomOccurrences.${idx}.${name}`, value);
  };

  const onAdd = () => {
    const symptomsLength = state?.symptomOccurrences?.length;
    _onChange(`symptomOccurrences.${symptomsLength}`, {
      type: "",
      severity: "",
      notes: "",
    });
  };

  const onDelete = (idx: number) => {
    const _symptoms =
      state?.symptomOccurrences?.filter(
        (s) => state?.symptomOccurrences?.indexOf(s) !== idx
      ) ?? [];
    _onChange("symptomOccurrences", _symptoms);
  };

  return (
    <div className={styles.section}>
      {state?.symptomOccurrences?.map((s, sidx) => (
        <Row className={styles.row} gutter={8} key={sidx}>
          <Col xs={24} sm={16} className={styles.col}>
            <div className={cx("Body1DarkGreySmall", styles.eventTitle)}>
              {t("healthEvent.symptomOccurrence.symptom")}
            </div>
            <Input
              name="type"
              onChange={(evt) => onChange(evt, sidx)}
              value={s?.type}
              size="middle"
              className={styles.input}
            />
          </Col>
          <Col xs={24} sm={8} className={styles.col}>
            <div className={cx("Body1DarkGreySmall", styles.eventTitle)}>
              {t("healthEvent.symptomOccurrence.severityTitle")}
            </div>
            <Select
              options={options(t)}
              value={s?.severity}
              onChange={(value, option) =>
                onSelectChange(value, option, "severity", sidx)
              }
              size="middle"
              className={styles.input}
            />
          </Col>
          <Col xs={24} sm={24} className={styles.col}>
            <div className={cx("Body1DarkGreySmall", styles.eventTitle)}>
              {t("healthEvent.symptomOccurrence.notes")}
            </div>
            <TextArea
              name="notes"
              onChange={(evt) => onChange(evt, sidx)}
              value={s?.notes}
              rows={1}
              size="middle"
              className={styles.input}
            />
          </Col>
          <CloseOutlined
            className={styles.closeIcon}
            onClick={() => onDelete(sidx)}
          />
        </Row>
      ))}
      <Row className={styles.buttonRow}>
        <Button
          type="primary"
          size="middle"
          className={styles.button}
          onClick={onAdd}
        >
          +
        </Button>
      </Row>
    </div>
  );
};

const styles = {
  section: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  row: css`
    display: flex;
    align-items: flex-start;
    padding: 0.75rem;
    border: 1px solid var(--primary-main);
    border-radius: 8px;
    width: 100%;
    margin-bottom: 0.5rem;
    position: relative;
  `,
  col: css`
    margin-bottom: 0.25rem;
  `,
  buttonRow: css`
    display: flex;
    align-items: flex-start;
    padding: 0.5rem;
  `,
  button: css`
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    padding-bottom: 2px;
    display: flex;
    justify-content: center;
  `,
  input: css`
    width: 100%;
  `,
  eventTitle: css`
    margin-bottom: 0.25rem;
  `,
  closeIcon: css`
    position: absolute;
    top: 6px;
    right: 6px;
    curson: pointer;
  `,
};
