import React from "react";
import { css, cx } from "@emotion/css";
import { Button, Col, Input, Row, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { CloseOutlined } from "@ant-design/icons";
import { MetricsState, VisitsState } from "models/healthEvent";
import { useDoctorsCachedData } from "../utils";
import { useI18n } from "providers/I18n";

type Props = {
  state: MetricsState;
  onChange: (path: string, value: string | VisitsState | VisitsState[]) => void;
};

export const DoctorVisit = (props: Props) => {
  const { t } = useI18n();
  const { state, onChange: _onChange } = props ?? {};
  const { doctors, doctorsUpdatedAt } = useDoctorsCachedData();

  const onChange = (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    idx: number
  ) => {
    const { name, value } = evt?.target ?? {};
    _onChange(`doctorVisits.${idx}.${name}`, value);
  };

  const onSelectChange = (
    value: string,
    _:
      | {
          label: string;
          value: string;
        }
      | {
          label: string;
          value: string;
        }[],
    name: string,
    idx: number
  ) => {
    _onChange(`doctorVisits.${idx}.${name}`, value);
  };

  const onAdd = () => {
    const visitsLength = state?.doctorVisits?.length;
    _onChange(`doctorVisits.${visitsLength}`, {
      doctorId: "",
      diagnosis: "",
      notes: "",
    });
  };

  const onDelete = (idx: number) => {
    const _visits =
      state?.doctorVisits?.filter(
        (s) => state?.doctorVisits?.indexOf(s) !== idx
      ) ?? [];
    _onChange("doctorVisits", _visits);
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => !!option && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const options = React.useMemo(
    () =>
      doctors?.map((d) => ({
        label: `${d?.firstName} ${d?.lastName}`,
        value: d?.id ?? "",
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [doctorsUpdatedAt]
  );

  return (
    <div className={styles.section}>
      {state?.doctorVisits?.map((d, didx) => (
        <Row className={styles.row} gutter={8} key={didx}>
          <Col xs={24} sm={8} className={styles.col}>
            <div className={cx("Body1DarkGreySmall", styles.eventTitle)}>
              {t("healthEvent.doctorVisit.doctorName")}
            </div>
            <Select
              showSearch
              allowClear
              options={options}
              value={d?.doctorId}
              onChange={(value, option) =>
                onSelectChange(value, option, "doctorId", didx)
              }
              optionFilterProp="children"
              filterOption={filterOption}
              size="middle"
              className={styles.input}
            />
          </Col>
          <Col xs={24} sm={16} className={styles.col}>
            <div className={cx("Body1DarkGreySmall", styles.eventTitle)}>
              {t("healthEvent.doctorVisit.diagnosis")}
            </div>
            <Input
              name="diagnosis"
              onChange={(evt) => onChange(evt, didx)}
              value={d?.diagnosis}
              size="middle"
              className={styles.input}
            />
          </Col>
          <Col xs={24} sm={24} className={styles.col}>
            <div className={cx("Body1DarkGreySmall", styles.eventTitle)}>
              {t("healthEvent.doctorVisit.notes")}
            </div>
            <TextArea
              name="notes"
              onChange={(evt) => onChange(evt, didx)}
              value={d?.notes}
              rows={1}
              size="middle"
              className={styles.input}
            />
          </Col>
          <CloseOutlined
            className={styles.closeIcon}
            onClick={() => onDelete(didx)}
          />
        </Row>
      ))}
      <Row className={styles.buttonRow}>
        <Button
          type="primary"
          size="middle"
          className={styles.button}
          onClick={onAdd}
        >
          +
        </Button>
      </Row>
    </div>
  );
};

const styles = {
  section: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  row: css`
    display: flex;
    align-items: flex-start;
    padding: 0.75rem;
    border: 1px solid var(--primary-main);
    border-radius: 8px;
    width: 100%;
    margin-bottom: 0.5rem;
    position: relative;
  `,
  col: css`
    margin-bottom: 0.25rem;
  `,
  buttonRow: css`
    display: flex;
    align-items: flex-start;
    padding: 0.5rem;
  `,
  button: css`
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    padding-bottom: 2px;
    display: flex;
    justify-content: center;
  `,
  input: css`
    width: 100%;
  `,
  eventTitle: css`
    margin-bottom: 0.25rem;
  `,
  closeIcon: css`
    position: absolute;
    top: 6px;
    right: 6px;
    curson: pointer;
  `,
};
