import React from "react";
import { css, cx } from "@emotion/css";
import { Button, Menu, message } from "antd";
import { HomeOutlined, PhoneOutlined, MailOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";

import { Layout } from "../AuthLanding/Layout";
import CreateDoctor from "./CreateDoctor";
import { createDoctor, deleteDoctor, updateDoctor } from "api/doctors";
import { Doctor } from "models/doctor";
import ConfirmationModal, { ModalInfo } from "components/ConfirmationModal";
import { useDoctorsCachedData } from "../utils";
import { useI18n } from "providers/I18n";

export const Doctors = () => {
  const { t } = useI18n();
  const [messageApi, contextHolder] = message.useMessage();
  const [open, setOpen] = React.useState<boolean>(false);
  const [doctor, setDoctor] = React.useState<Doctor | undefined>();
  const [error, setError] = React.useState<any>();
  const [modalInfo, setModalInfo] = React.useState<ModalInfo>(undefined);

  const { doctors, invalidateDoctorsCache } = useDoctorsCachedData();

  const { mutate: _createDoctor } = useMutation(createDoctor, {
    onSuccess: (r) => {
      invalidateDoctorsCache();
      onClose();
      messageApi.open({
        type: "success",
        content: r?.message,
      });
    },
    onError: (err: Error) => {
      const _err = JSON.parse(err.message);
      setError(_err);
      messageApi.open({
        type: "error",
        content: _err?.error,
      });
    },
  });

  const { mutate: _updateDoctor } = useMutation(updateDoctor, {
    onSuccess: (r) => {
      invalidateDoctorsCache();
      onClose();
      messageApi.open({
        type: "success",
        content: r?.message,
      });
    },
    onError: (err: Error) => {
      const _err = JSON.parse(err.message);
      setError(_err);
      messageApi.open({
        type: "error",
        content: _err?.error,
      });
    },
  });

  const { mutate: _deleteDoctor, isLoading: isDeleting } = useMutation(
    deleteDoctor,
    {
      onSuccess: (r) => {
        setModalInfo(undefined);
        invalidateDoctorsCache();
        messageApi.open({
          type: "success",
          content: r?.message,
        });
      },
      onError: (err: Error) => {
        const _err = JSON.parse(err.message);
        setError(_err);
        messageApi.open({
          type: "error",
          content: _err?.error,
        });
      },
    }
  );

  const create = (doctor: Doctor) => _createDoctor(doctor);
  const update = (doctor: Partial<Doctor>) => _updateDoctor(doctor);

  const onDelete = (id: string) => {
    setModalInfo({
      title: t('doctors.deleteTitle'),
      type: "primary",
      danger: true,
      description: t('doctors.deleteConfirmation'),
      submitLabel: "delete",
      isSubmitDisabled: isDeleting,
      cancelLabel: "cancel",
      onSubmit: async () => await _deleteDoctor(id),
    });
  };

  const onOpen = (id?: string) => {
    setOpen(true);
    !!id && setDoctor(() => doctors?.find((d) => d?.id === id));
  };
  const onClose = () => {
    setOpen(false);
    setDoctor(undefined);
    setError(undefined);
  };
  const isModalOpen = Boolean(modalInfo);

  const items = (id?: string) => [
    {
      key: 1,
      label: t("menu.edit"),
      onClick: () => onOpen(id),
    },
    { key: 2, label: t("menu.delete"), onClick: () => !!id && onDelete(id) },
  ];

  return (
    <>
      {contextHolder}
      <Layout>
        <div className={styles.container}>
          <div className={styles.title}>
            <div className="HeadlineH2DarkGreyBold">{t("doctors.title")}</div>
            <Button type="primary" onClick={() => onOpen()}>
              {`+ ${t("doctors.addNew")}`}
            </Button>
          </div>
          <span>{t("doctors.description")}</span>
          <div className={styles.doctors}>
            {doctors?.map((d, didx) => (
              <div className={styles.card} key={didx}>
                <div className={styles.cardSection}>
                  <div className={styles.firstWrapper}>
                    <div className={styles.doctorName}>
                      <div className="HeadlineH4DarkGreyBold">{`${d?.firstName}, ${d?.lastName}`}</div>
                      {!!d?.specialty ? (
                        <div
                          className={cx(
                            "Body1DarkGreyRegular",
                            styles.lineHeight
                          )}
                        >
                          {d?.specialty}
                        </div>
                      ) : null}
                    </div>
                    <Menu
                      mode="horizontal"
                      items={items(d?.id)}
                      className={styles.menu}
                    />
                  </div>
                </div>
                <div className={styles.cardSection}>
                  <div className={styles.detailsWrapper}>
                    <div className={styles.iconWrapper}>
                      <HomeOutlined className={styles.icon} />:
                      <span
                        className={cx("Body1DarkGreyRegular", styles.details)}
                      >
                        {d?.address}
                      </span>
                    </div>
                  </div>
                  <div className={styles.detailsWrapper}>
                    <div className={styles.iconWrapper}>
                      <PhoneOutlined className={styles.icon} />:
                      <span
                        className={cx("Body1DarkGreyRegular", styles.details)}
                      >
                        {d?.phoneNumber}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className={styles.iconWrapper}>
                      <MailOutlined className={styles.icon} />:
                      <span
                        className={cx("Body1DarkGreyRegular", styles.details)}
                      >
                        {d?.email}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Layout>
      {!!open && (
        <CreateDoctor
          open={open}
          onClose={onClose}
          create={create}
          update={update}
          error={error}
          doctor={doctor}
        />
      )}
      <ConfirmationModal
        open={isModalOpen}
        onClose={() => setModalInfo(undefined)}
        {...modalInfo}
      />
    </>
  );
};

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    width: 100%;
    @media (max-width: 768px) {
      margin-top: 4rem;
    }
  `,
  title: css`
    display: flex;
    justify-content: space-between;
    align-items: center; /* Ensures the title and button are vertically aligned */
    margin-bottom: 0.5rem;
    width: 100%; /* Ensures it takes full width */
  `,
  doctors: css`
    margin-top: 3.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `,
  card: css`
    background-color: var(--white);
    padding: 0.5rem 1rem;
    border-left: 5px solid var(--primary-main);
    border-radius: 0.375rem;
    @media (max-width: 600px) {
      padding: 0.5rem;
      min-height: 100px;
      padding-top: 0;
    }
  `,
  cardSection: css`
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0.5rem 1rem;
    &:not(:last-child) {
      border-bottom: 1px solid var(--grey);
    }
    &:not(:first-child) {
      padding: 1rem 0.5rem 1rem;
    }
    @media (max-width: 600px) {
      padding: 0.5rem;
      padding-right: 0;
    }
  `,
  firstWrapper: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  doctorName: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  menu: css`
    min-width: 0;
    justify-content: center;
    border: none;
    transform: rotate(90deg);
    width: fit-content;
    height: fit-content;
    align-items: center;
  `,
  detailsWrapper: css`
    margin-bottom: 0.25rem;
  `,
  iconWrapper: css`
    display: flex;
    align-items: center;
  `,
  icon: css`
    width: 14px;
    margin-right: 2px;
  `,
  lineHeight: css`
    line-height: 24px;
  `,
  details: css`
    margin-left: 8px;
  `,
};
