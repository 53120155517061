import { useAuth } from "providers/A12n";
import { Navigate, useLocation } from "react-router-dom";

export const AuthLanding = () => {
  const { user, logout } = useAuth();
  let location = useLocation();
  const { family } = user?.userData ?? {};

  if (!user?.decoded) {
    logout();
    return <Navigate to="/" state={{ from: location }} />;
  }

  let landing = !!family ? "/timeline" : "/family";

  return <Navigate to={landing} />;
};
