import * as React from "react";
import { http } from "api/http";
import { useUserCachedData } from "routes/Main/utils";

export type I18nContextType = {
  lang: string;
  t: (key: string) => string;
  onLangChange?: (lang?: string) => void;
};

type I18nState = I18nContextType;

const DEFAULT_LANG = "en";

const Initial: I18nState = {
  lang: DEFAULT_LANG,
  t: (x) => x,
};

export type I18nProviderProps = {
  children: React.ReactNode | React.ReactNodeArray;
};

export const I18nContext = React.createContext<I18nContextType>(Initial);

export const I18nProvider = ({ children }: I18nProviderProps) => {
  const { userData, userUpdatedAt } = useUserCachedData();
  const [accessToken, setAccessToken] = React.useState(
    localStorage.getItem("accessToken")
  );
  const [i18n, setI18n] = React.useState<I18nState>(Initial);

  // const accessToken = localStorage.getItem("accessToken");

  const onLangChange = (lang?: string) => {
    !!lang && setI18n((s) => ({ ...s, lang }));
  };

  React.useEffect(() => {
    const handleStorageChange = () => {
      setAccessToken(localStorage.getItem("accessToken"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  React.useEffect(() => {
    !!userData?.language &&
      setI18n((s) => ({ ...s, lang: userData?.language }));
    const fetchData = async () => {
      await http
        .get(`translations/${userData?.language || i18n?.lang}`)
        .json()
        .then((data: any) => {
          setI18n((s: I18nState) => ({
            ...s,
            t: (key: string) => data[key] ?? key,
          }));
        });
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUpdatedAt, i18n?.lang, accessToken, userData?.language]);

  const contextValue = React.useMemo(() => {
    return { ...i18n, onLangChange };
  }, [i18n]);
  return (
    <I18nContext.Provider value={contextValue}>{children}</I18nContext.Provider>
  );
};

export function useI18n() {
  const i18n = React.useContext(I18nContext);
  return i18n;
}
