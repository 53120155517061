import React from "react";
import {
  TokensPld,
  clearTokens,
  getRefreshTokens,
  getTokens,
  registerEmail,
  saveTokens,
  verifyEmail,
} from "api/auth";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { Loading } from "components/Loading";
import { http } from "api/http";
import { UserData } from "models/user";
import { useQueryClient } from "react-query";

const { EventEmitter } = require("events");

const eventEmitter = new EventEmitter();

const isExpired = (token?: string) => {
  if (!token) return true;
  try {
    const decoded = jwtDecode(token);
    return Date.now() > +new Date((decoded.exp ?? 0) * 1000);
  } catch (err) {
    return false;
  }
};

const getActiveToken = (key: string) => {
  const token = localStorage.getItem(key) || undefined;
  return isExpired(token) ? undefined : token;
};

export const getToken = async () => {
  try {
    const accessToken = getActiveToken("accessToken");
    const refreshToken = getActiveToken("refreshToken");
    if (accessToken) {
      return Promise.resolve(accessToken);
    } else if (refreshToken) {
      const res = await getRefreshTokens();
      saveTokens(res);
      return res.accessToken;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};

const getUser = async (token: string | null) => {
  try {
    const _token = token || (await getToken());
    return {
      decoded: _token ? jwtDecode(_token) : null,
      changedAt: Date.now(),
    };
  } catch (_e) {
    return {
      decoded: null,
      changedAt: Date.now(),
    };
  }
};

const getUserData = async (accessToken: string | null) => {
  if (!accessToken) return;
  try {
    const data = await http.get("user").json();

    return {
      userData: data as UserData,
      changedAt: Date.now(),
    };
  } catch (error) {
    // do nothing
  }
};

export const login = async (payload: TokensPld) => {
  const tokens = await getTokens(payload);
  saveTokens(tokens);
  return tokens;
};

export const logout = async () => {
  clearTokens();
  await eventEmitter.emit("logout");
};

export const register = async (payload: TokensPld) => {
  const res = await registerEmail(payload);
  return res;
};

export const verify = async (token: string) => {
  const res = await verifyEmail(token);
  return res;
};

type AuthContextType = {
  user: {
    changedAt: number;
    decoded: JwtPayload | null;
    userData?: UserData;
  };
  logout: () => Promise<void>;
};

export const AuthContext = React.createContext<AuthContextType | undefined>(
  undefined
);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const queryCache = useQueryClient();
  const [user, setUser] = React.useState<{
    changedAt: number;
    decoded: JwtPayload | null;
    userData?: UserData;
  }>({ changedAt: 0, decoded: null, userData: undefined });
  const { changedAt, decoded } = user;

  const getInitUser = async () => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      saveTokens({ accessToken });
    }
    const user = await getUser(accessToken);
    const userData = await getUserData(accessToken);

    setUser({ ...user, ...userData });
  };

  const onLogout = React.useCallback(() => {
    setUser({ decoded: null, changedAt: Date.now() });
    queryCache.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    eventEmitter.on("logout", onLogout);
    return () => {
      eventEmitter.off("logout", onLogout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (changedAt === 0) {
      getInitUser();
    }
    if (changedAt > 0 && decoded === null) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedAt]);

  const contextValue = React.useMemo(
    () => ({
      user,
      logout,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [changedAt]
  );

  return changedAt > 0 ? (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  ) : (
    <Loading />
  );
}

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("check useAuth usage");
  }
  return context;
};
