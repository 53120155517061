import { css, cx } from "@emotion/css";

export type Props = {
  title: string;
  className?: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
};

export function NoData(props: Props) {
  const { icon, title, className, children } = props;
  return (
    <div className={cx(styles.root, className)}>
      {icon ? <div className={styles.icon}>{icon}</div> : null}
      <h4 className="Body1DarkGreySmall">{title}</h4>
      {children}
    </div>
  );
}

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
  `,
  icon: css`
    > svg {
      height: 100px;
    }
  `,
};
