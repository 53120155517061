import React from "react";
import { Select } from "antd";
import { css } from "@emotion/css";
import { useI18n } from "providers/I18n";

const LanguageSwitch = () => {
  const { lang: i18nLang, onLangChange } = useI18n();
  const [lang, setLang] = React.useState<string>("en");

  const onChange = (lang: string) => {
    setLang(lang);
    !!onLangChange && onLangChange(lang);
  };

  const options = [
    { label: "GR", value: "el" },
    { label: "EN", value: "en" },
  ];

  React.useEffect(() => {
    setLang(i18nLang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Select
        options={options}
        value={lang}
        onChange={onChange}
        className={styles.root}
        size="small"
        suffixIcon={null}
        popupClassName={styles.popup}
      />
    </div>
  );
};

export default LanguageSwitch;

const styles = {
  root: css`
    text-align: center;
    min-width: 46px;
  `,
  popup: css`
    &.ant-select-dropdown {
      .ant-select-item {
        padding: 5px 8px;
      }
    }
  `,
};
