import { Doctor, DoctorDelete, DoctorResponse } from "models/doctor";
import { http } from "./http";

export const fetchDoctors = async () => {
  const res = await http.get("doctors").json();
  return res as Doctor[];
};

export const createDoctor = async (props: Doctor) => {
  const res = await http.post("doctors", { json: props }).json();
  return res as DoctorResponse;
};

export const updateDoctor = async (pld: Partial<Doctor>) => {
  const { id, ...doctor } = pld;
  const res = await http.patch(`doctors/${id}`, { json: doctor }).json();
  return res as DoctorResponse;
};

export const deleteDoctor = async (id: string) => {
  const res = await http.delete(`doctors/${id}`).json();
  return res as DoctorDelete;
};
