import ky from "ky";
import { afterResHooks } from "./http/hooks";

const authClient = ky.extend({
  throwHttpErrors: true,
  retry: 2,
  prefixUrl: `${process.env.REACT_APP_BASE_API_URL}/auth`,
  hooks: {
    afterResponse: [afterResHooks.handleEmpty, afterResHooks.handleErrors],
  },
});

export type TokensPld = {
  email: string;
  password: string;
};

type TokensRes = {
  token: string;
  expires_in: number;
  jti: string;
  refreshToken: string;
  scope: string;
  token_type: string;
};

export type Tokens = {
  accessToken: string;
  refreshToken?: string;
};

export const getTokens = (values: TokensPld) =>
  authClient
    .post("login", {
      json: values,
    })
    .json()
    .then((res) => {
      const { token, refreshToken } = res as TokensRes;
      return {
        accessToken: token,
        refreshToken: refreshToken,
      };
    });

export const registerEmail = (values: TokensPld) =>
  authClient
    .post("register", { json: values })
    .json()
    .then((res) => {
      const { message } = res as { message: string };
      return { message };
    });

export const verifyEmail = (token: string) =>
  authClient
    .get(`verify/${token}`)
    .json()
    .then((res) => {
      const { message } = res as { message: string };
      return { message };
    });

export const getRefreshTokens = () =>
  authClient
    .get("refreshToken")
    .json()
    .then((res) => {
      const { token, refreshToken } = res as TokensRes;
      return {
        accessToken: token,
        refreshToken: refreshToken,
      };
    });

export const saveTokens = ({ accessToken, refreshToken }: Tokens) => {
  localStorage.setItem("accessToken", accessToken);
  refreshToken && localStorage.setItem("refreshToken", refreshToken);
};

export const clearTokens = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};
