import {
  Family,
  FamilyResponse,
  Member,
  MemberDelete,
  MemberResponse,
} from "models/family";
import { http } from "./http";

export const fetchFamily = async () => {
  const res = await http.get("family").json();
  return res as Family;
};

export const createFamily = async (props: Partial<Family>) => {
  const res = await http.post("family", { json: props }).json();
  return res as FamilyResponse;
};

export const updateFamily = async (pld: Partial<Family>) => {
  const { id, ...family } = pld;
  const res = await http.patch(`family/${id}`, { json: family }).json();
  return res as FamilyResponse;
};

export const updateMember = async (pld: Partial<Family>) => {
  const { id, ...member } = pld;
  const res = await http.patch(`family/member/${id}`, { json: member }).json();
  return res as MemberResponse;
};

export const createMember = async (props: Omit<Member, "id">) => {
  const res = await http.post("family/member", { json: props }).json();
  return res as MemberResponse;
};

export const deleteMember = async (id: string) => {
  const res = await http.delete(`family/member/${id}`).json();
  return res as MemberDelete;
};
