import React from "react";
import {
  Button,
  DatePicker,
  DatePickerProps,
  Modal,
  Radio,
  RadioChangeEvent,
  Select,
  message,
} from "antd";
import {
  UserOutlined,
  ReconciliationOutlined,
  MedicineBoxOutlined,
  BugOutlined,
  ContactsOutlined,
  ClockCircleOutlined,
  ExceptionOutlined,
  EditOutlined,
  AppstoreAddOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { set } from "lodash/fp";
import { css, cx } from "@emotion/css";
import TextArea from "antd/es/input/TextArea";
import { DoctorVisit } from "./DoctorVisit";
import { HealthMetric } from "./HealthMetric";
import { MedicationIntake } from "./MedicationIntake";
import { SymptomOccurrence } from "./SymptomOccurrence";
import {
  IntakesState,
  Metric,
  MetricState,
  MetricsState,
  SymptomState,
  VisitsState,
} from "models/healthEvent";
import { useMutation, useQueryClient } from "react-query";
import { createHealthEvent } from "api/healthEvent";
import { Member } from "models/family";
import { useI18n } from "providers/I18n";

type Props = {
  open: boolean;
  onClose: VoidFunction;
  updateEvent?: (event: Partial<MetricsState>) => void;
  members: Member[];
  metrics: Metric[];
  metricsUpdatedAt?: number;
  event?: MetricsState;
  updateError?: any;
};

const InitialState = {
  createdForId: "",
  eventTime: "",
  metrics: [
    {
      typeId: "",
      value: "",
      unitId: "",
    },
  ],
  medicationIntakes: [
    {
      medicineName: "",
      dosage: "",
      dosageUnitId: "",
      comment: "",
    },
  ],
  symptomOccurrences: [
    {
      type: "",
      severity: "",
      notes: "",
    },
  ],
  doctorVisits: [
    {
      doctorId: "",
      diagnosis: "",
      notes: "",
    },
  ],
  description: "",
} as MetricsState;

type RadioButton = "metric" | "medication" | "symptom" | "doctor";

const CreateHealthEvent = (props: Props) => {
  const { t } = useI18n();
  const queryClient = useQueryClient();
  const [messageApi, contextHolder] = message.useMessage();
  const {
    open,
    onClose,
    members,
    metrics,
    metricsUpdatedAt,
    event,
    updateEvent,
    updateError,
  } = props;
  const [state, setState] = React.useState<MetricsState>(() => InitialState);
  const [radio, setRadio] = React.useState<RadioButton>("metric");
  const [error, setError] = React.useState<any>();

  const { mutate: createEvent } = useMutation(createHealthEvent, {
    onSuccess: (r) => {
      queryClient.invalidateQueries({ queryKey: ["health-events"] });
      handleClose();
      messageApi.open({
        type: "success",
        content: r?.message,
      });
    },
    onError: (err: Error) => {
      const _err = JSON.parse(err.message);
      setError(_err);
      messageApi.open({
        type: "error",
        content: _err?.error,
      });
    },
  });

  const handleOk = () => {
    const _metrics =
      state?.metrics?.filter((s) => !!s?.typeId || !!s?.unitId || !!s?.value) ??
      [];
    const _intakes =
      state?.medicationIntakes?.filter(
        (s) =>
          !!s?.medicineName || !!s?.dosage || !!s?.dosageUnitId || !!s?.comment
      ) ?? [];
    const _symptoms =
      state?.symptomOccurrences?.filter(
        (s) => !!s?.type || !!s?.severity || !!s?.notes
      ) ?? [];
    const _visits =
      state?.doctorVisits?.filter(
        (s) => !!s?.doctorId || !!s?.diagnosis || !!s?.notes
      ) ?? [];
    const pld = {
      ...state,
      eventTime: new Date(state?.eventTime).toISOString(),
      metrics: _metrics,
      medicationIntakes: _intakes,
      symptomOccurrences: _symptoms,
      doctorVisits: _visits,
    } as MetricsState;
    !!event && !!updateEvent ? updateEvent(pld) : createEvent(pld);
  };

  const children = members
    ?.filter((f) => f?.familyRole === "child")
    ?.map((c) => ({ label: c?.firstname, value: c?.id }));

  const handleClose = () => {
    setState(() => InitialState);
    onClose();
  };

  const onChange = (
    path: string,
    value:
      | string
      | MetricState
      | MetricState[]
      | IntakesState
      | IntakesState[]
      | SymptomState
      | SymptomState[]
      | VisitsState
      | VisitsState[]
  ) => {
    setState((s) => set(path, value, s));
    setError(undefined);
  };

  const onRadioChange = (evt: RadioChangeEvent) => {
    const { value } = evt?.target;
    setRadio(value);
  };

  const onDateChange: DatePickerProps["onChange"] = (_, dateString) => {
    setState((s) => ({ ...s, eventTime: dateString as string }));
  };

  const onSelectChange = (value: string) => {
    setState((s) => ({ ...s, createdForId: value }));
  };

  const onInputChange = (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = evt?.target ?? {};
    setState((s) => ({ ...s, [name]: value }));
  };

  React.useEffect(() => {
    !!event && setState(event);
  }, [event]);

  React.useEffect(() => {
    !state?.eventTime &&
      setState((s) => ({
        ...s,
        eventTime: new Date().toString(),
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  React.useEffect(() => {
    if (open && children?.length && !state?.createdForId) {
      setState((s) => ({ ...s, createdForId: children[0]?.value || "" }));
    }
  }, [open, children, state?.createdForId]);

  return (
    <Modal
      title={
        !!event ? t("healthEvent.update.title") : t("healthEvent.add.title")
      }
      open={open}
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={handleClose}>
          {t("button.cancel")}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleOk}
          disabled={!state?.createdForId || !state?.eventTime}
        >
          {!!event ? t("button.update") : t("button.add")}
        </Button>,
      ]}
      className={styles.modal}
      width={600}
    >
      {contextHolder}
      <div className={styles.user}>
        <UserOutlined className={styles.icon} />
        <Select
          options={children}
          value={state?.createdForId}
          onChange={onSelectChange}
          className={styles.select}
          size="large"
          placeholder="Child"
        />
      </div>
      <div className={styles.section}>
        <div className={cx("Body1DarkGreyRegular", styles.eventTitle)}>
          {/* {t("healthEvent.eventTime")} */}
        </div>
        <ClockCircleOutlined className={styles.icon} />
        <DatePicker
          onChange={onDateChange}
          showTime
          size="large"
          value={!!state?.eventTime ? dayjs(state?.eventTime) : dayjs()}
          className={cx(styles.datepicker, styles.input)}
          suffixIcon={null}
        />
      </div>
      <div className={styles.section}>
        <div className={cx("Body1DarkGreyRegular", styles.eventTitle)}>
          {/* {t("healthEvent.description")} */}
          <EditOutlined className={styles.icon} />
        </div>
        <TextArea
          name="description"
          rows={3}
          value={state?.description}
          size="middle"
          onChange={onInputChange}
        />
      </div>
      <div className={cx(styles.spaceUp, styles.section)}>
        <div className={cx("Body1DarkGreyRegular", styles.eventTitle)}>
          {/* {t("healthEvent.eventType")} */}
          <ExceptionOutlined className={styles.icon} />
        </div>
        <Radio.Group
          defaultValue="metric"
          buttonStyle="solid"
          // className={styles.radioGroup}
          className={cx(styles.radioGroup, styles.input)}
          onChange={onRadioChange}
        >
          <Radio.Button value="metric" className={styles.radioButton}>
            <ReconciliationOutlined className={styles.eventIcon} />
            <div className={styles.buttonDetails}>
              {t("healthEvent.healthMetric.title")}
            </div>
          </Radio.Button>
          <Radio.Button value="medication" className={styles.radioButton}>
            <MedicineBoxOutlined className={styles.eventIcon} />
            <div className={styles.buttonDetails}>
              {t("healthEvent.medicationIntake.title")}
            </div>
          </Radio.Button>
          <Radio.Button value="symptom" className={styles.radioButton}>
            <BugOutlined className={styles.eventIcon} />
            <div className={styles.buttonDetails}>
              {t("healthEvent.symptomOccurrence.title")}
            </div>
          </Radio.Button>
          <Radio.Button value="doctor" className={styles.radioButton}>
            <ContactsOutlined className={styles.eventIcon} />
            <div className={styles.buttonDetails}>
              {t("healthEvent.doctorVisit.title")}
            </div>
          </Radio.Button>
        </Radio.Group>
      </div>
      <div className={styles.section}>
        <AppstoreAddOutlined className={styles.icon} />
        <div className={styles.event}>
          {radio === "metric" && (
            <HealthMetric
              metrics={metrics}
              metricsUpdatedAt={metricsUpdatedAt}
              state={state}
              onChange={onChange}
            />
          )}
          {radio === "medication" && (
            <MedicationIntake state={state} onChange={onChange} />
          )}
          {radio === "symptom" && (
            <SymptomOccurrence state={state} onChange={onChange} />
          )}
          {radio === "doctor" && (
            <DoctorVisit state={state} onChange={onChange} />
          )}
        </div>
      </div>
      {(!!error || !!updateError) && (
        <span className={styles.error}>
          {error?.error || updateError?.error}
        </span>
      )}
    </Modal>
  );
};

export default CreateHealthEvent;

const styles = {
  modal: css`
    border-radius: 10px;
    .ant-modal-content {
      padding: 0;
    }
    .ant-modal-header {
      .ant-modal-title {
        font-size: 1.4rem !important;
        font-weight: 400;
        padding: 2rem 2rem !important;
        background-color: aliceblue;
        border-radius: 10px;
      }
    }
    .ant-modal-body {
      padding: 2rem 2rem 0rem;
    }
    .ant-modal-footer {
      padding: 1rem;
    }
  `,
  user: css`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  `,
  section: css`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  `,
  radioGroup: css`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    .ant-radio-button-wrapper {
      display: flex;
      flex-direction: column;
      line-height: 1;
      text-align: center;
    }
  `,
  radioButton: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px;
    min-height: 84px;
    > span {
      height: 100%;
      align-content: center;
      justify-content: center;
    }
  `,
  buttonDetails: css`
    line-height: 1.1;
    height: 30px;
    display: flex;
    align-items: center;
    padding-top: 8px;
  `,
  eventTitle: css`
    margin-bottom: 0.5rem;
  `,
  eventIcon: css`
    height: 1.5rem;
    > svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  `,
  icon: css`
    margin-right: 8px;
    > svg {
      width: 16px;
      height: 16px;
    }
  `,
  select: css`
    min-width: 310px;
    @media (max-width: 600px) {
      width: 100%;
    }
    &.ant-select-single.ant-select-lg > div > span {
      font-size: 18px;
    }
  `,
  datepicker: css`
    min-width: 310px;
    @media (max-width: 600px) {
      width: 100%;
    }
  `,
  input: css`
    // margin-bottom: 1rem;
  `,
  event: css`
    width: 100%;
  `,
  error: css`
    color: var(--red);
  `,
  spaceUp: css`
    margin-top: 30px;
  `,
};
