import React from "react";
import { css, cx } from "@emotion/css";
import { Button, Col, Input, Row, Select } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { IntakesState, MetricsState } from "models/healthEvent";
import { NumericInput } from "components/Inputs";
import { fetchMedicationUnits } from "api/healthEvent";
import { useQuery } from "react-query";
import TextArea from "antd/es/input/TextArea";
import { useI18n } from "providers/I18n";

type Props = {
  state: MetricsState;
  onChange: (
    path: string,
    value: string | IntakesState | IntakesState[]
  ) => void;
};

export const MedicationIntake = (props: Props) => {
  const { t } = useI18n();
  const { state, onChange: _onChange } = props;

  const { data: medicineUnits, dataUpdatedAt: unitsUpdatedAt } = useQuery(
    ["medicine-units"],
    fetchMedicationUnits
  );

  const onChange = (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    idx: number
  ) => {
    const { name, value } = evt?.target ?? {};
    _onChange(`medicationIntakes.${idx}.${name}`, value);
  };

  const onNumericChange = (value: string, name: string, idx: number) => {
    _onChange(`medicationIntakes.${idx}.${name}`, value);
  };

  const onSelectChange = (
    value: string,
    _:
      | {
          label: string;
          value: string;
        }
      | {
          label: string;
          value: string;
        }[],
    name: string,
    idx: number
  ) => {
    _onChange(`medicationIntakes.${idx}.${name}`, value);
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => !!option && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const onAdd = () => {
    const intakesLength = state?.medicationIntakes?.length;
    _onChange(`medicationIntakes.${intakesLength}`, {
      medicineName: "",
      dosage: "",
      dosageUnitId: "",
      comment: "",
    });
  };

  const onDelete = (idx: number) => {
    const _intakes =
      state?.medicationIntakes?.filter(
        (m) => state?.medicationIntakes?.indexOf(m) !== idx
      ) ?? [];
    _onChange("medicationIntakes", _intakes);
  };

  const options = React.useMemo(
    () =>
      medicineUnits?.map((m) => ({
        label: m?.unit,
        value: m?.id,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [unitsUpdatedAt]
  );

  return (
    <div className={styles.section}>
      {state?.medicationIntakes?.map((m, midx) => (
        <Row className={styles.row} gutter={8} key={midx}>
          <Col xs={24} sm={12} className={styles.col}>
            <div className={cx("Body1DarkGreySmall", styles.eventTitle)}>
              {t("healthEvent.medicationIntake.medicineName")}
            </div>
            <Input
              name="medicineName"
              onChange={(evt) => onChange(evt, midx)}
              value={m?.medicineName}
              size="middle"
              className={styles.input}
            />
          </Col>
          <Col xs={12} sm={6} className={styles.col}>
            <div className={cx("Body1DarkGreySmall", styles.eventTitle)}>
              {t("healthEvent.medicationIntake.dosage")}
            </div>
            <NumericInput
              name="dosage"
              onChange={(v) => onNumericChange(v, "dosage", midx)}
              value={String(m?.dosage)}
              size="middle"
              className={styles.input}
            />
          </Col>
          <Col xs={12} sm={6} className={styles.col}>
            <div className={cx("Body1DarkGreySmall", styles.eventTitle)}>
              {t("healthEvent.medicationIntake.dosageUnitTitle")}
            </div>
            <Select
              showSearch
              allowClear
              options={options}
              value={m?.dosageUnitId}
              onChange={(value, option) =>
                onSelectChange(value, option, "dosageUnitId", midx)
              }
              optionFilterProp="children"
              filterOption={filterOption}
              size="middle"
              className={styles.input}
            />
          </Col>
          <Col xs={24} md={24} className={cx(styles.col, styles.lastLine)}>
            <div className={cx("Body1DarkGreySmall", styles.eventTitle)}>
              {t("healthEvent.medicationIntake.comment")}
            </div>
            <TextArea
              name="comment"
              onChange={(evt) => onChange(evt, midx)}
              value={m?.comment}
              rows={1}
              size="middle"
              className={styles.input}
            />
          </Col>
          <CloseOutlined
            className={styles.closeIcon}
            onClick={() => onDelete(midx)}
          />
        </Row>
      ))}
      <Row className={styles.buttonRow}>
        <Button
          type="primary"
          size="middle"
          className={styles.button}
          onClick={onAdd}
        >
          +
        </Button>
      </Row>
    </div>
  );
};

const styles = {
  section: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  row: css`
    display: flex;
    align-items: flex-start;
    padding: 0.75rem;
    border: 1px solid var(--primary-main);
    border-radius: 8px;
    width: 100%;
    margin-bottom: 0.5rem;
    position: relative;
  `,
  col: css`
    margin-bottom: 0.25rem;
  `,
  buttonRow: css`
    display: flex;
    align-items: flex-start;
    padding: 0.5rem;
  `,
  button: css`
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    padding-bottom: 2px;
    display: flex;
    justify-content: center;
  `,
  input: css`
    width: 100%;
  `,
  eventTitle: css`
    margin-bottom: 0.25rem;
  `,
  closeIcon: css`
    position: absolute;
    top: 6px;
    right: 6px;
    curson: pointer;
  `,
  lastLine: css`
    margin-top: 0.5rem;
  `,
};
