import { css, cx } from "@emotion/css";

type CardProps = {
  img: string;
  title: string;
  body: string;
  alt: string;
};

export const Card = (props: CardProps) => {
  const { img, title, body, alt } = props;
  return (
    <div className={styles.card}>
      <img alt={alt} src={img} className={styles.icon} />
      <div className={styles.textWrapper}>
        <div className={cx("HeadlineH3DarkGreyBold", styles.title)}>
          {title}
        </div>
        <div className={cx("Body1DarkGreyRegular", styles.body)}>{body}</div>
      </div>
    </div>
  );
};

const styles = {
  card: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    box-shadow: 0 0.5rem 1rem rgba(33, 37, 41, 0.15);
    cursor: auto;
    background-color: var(--white);
    border: 1px sold var(--grey);
    border-radius: 8px;
    height: 400px;
    text-align: center;
  `,
  icon: css`
    max-width: 100%;
    height: 150px;
    padding: 1rem 0;
  `,
  textWrapper: css`
    padding: 1rem 0;
  `,
  title: css`
    margin-bottom: 0.5rem;
  `,
  body: css`
    display: flex;
    flex-wrap: wrap;
  `,
};
