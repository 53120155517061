import { css } from "@emotion/css";
import { Spin } from "antd";

export const Loading = () => (
  <div className={styles.root}>
    <Spin size="large" />
  </div>
);

const styles = {
  root: css`
    padding: 1.5rem;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
  `,
};
