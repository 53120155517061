import { fetchUser } from "api/user";
import { fetchDoctors } from "api/doctors";
import { fetchFamily } from "api/family";
import { fetchHealthMetricTypes } from "api/healthEvent";
import { useQuery, useQueryClient } from "react-query";

export function useUserCachedData() {
  const queryClient = useQueryClient();
  const accessToken = localStorage.getItem("accessToken");

  const { data: user, dataUpdatedAt } = useQuery(["user"], fetchUser, {
    refetchOnMount: false,
    enabled: !!accessToken,
  });

  const invalidateUserCache = () => {
    queryClient.invalidateQueries("user");
  };

  return { userData: user, userUpdatedAt: dataUpdatedAt, invalidateUserCache };
}

export function useFamilyCachedData() {
  const queryClient = useQueryClient();

  const { data: familyData, dataUpdatedAt } = useQuery(
    ["family"],
    fetchFamily,
    {
      refetchOnMount: false,
    }
  );

  const invalidateFamilyCache = () => {
    queryClient.invalidateQueries("family");
  };

  return {
    family: familyData,
    familyUpdatedAt: dataUpdatedAt,
    invalidateFamilyCache,
  };
}

export function useDoctorsCachedData() {
  const queryClient = useQueryClient();

  const { data: doctors, dataUpdatedAt } = useQuery(["doctors"], fetchDoctors, {
    refetchOnMount: false,
  });

  const invalidateDoctorsCache = () => {
    queryClient.invalidateQueries("doctors");
  };

  return { doctors, doctorsUpdatedAt: dataUpdatedAt, invalidateDoctorsCache };
}

export function useMetricsCachedData() {
  const queryClient = useQueryClient();

  const { data: metrics, dataUpdatedAt } = useQuery(
    ["metrics"],
    fetchHealthMetricTypes,
    {
      refetchOnMount: false,
    }
  );

  const invalidateMetricsCache = () => {
    queryClient.invalidateQueries("metrics");
  };

  return { metrics, metricsUpdatedAt: dataUpdatedAt, invalidateMetricsCache };
}
