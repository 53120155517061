import React from "react";
import { Input, Select, message } from "antd";
import { css } from "@emotion/css";
import { useMutation } from "react-query";
import { Layout } from "../AuthLanding/Layout";
import { updateUser } from "api/user";
import { useI18n } from "providers/I18n";
import { useUserCachedData } from "../utils";

type SettingsState = {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  language?: string;
};

export const Settings = () => {
  const { t, onLangChange } = useI18n();
  const [messageApi, contextHolder] = message.useMessage();

  const [error, setError] = React.useState<any>();
  const [state, setState] = React.useState<SettingsState>({ language: "gr" });

  const { userData, userUpdatedAt, invalidateUserCache } = useUserCachedData();

  const { mutate: update } = useMutation(updateUser, {
    onSuccess: (r) => {
      invalidateUserCache();
      messageApi.open({
        type: "success",
        content: r?.message,
      });
    },
    onError: (err: Error) => {
      const _err = JSON.parse(err.message);
      setError(_err);
      messageApi.open({
        type: "error",
        content: _err?.message,
      });
    },
  });

  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt?.target ?? {};
    setError(undefined);
    setState((s) => ({ ...s, [name]: value }));
  };

  const onSelectChange = (value: string) => {
    setState((s) => ({ ...s, language: value }));
    update({ language: value });
    !!onLangChange && onLangChange(value);
  };

  const onBlur = () => {
    update(state);
  };

  React.useEffect(() => {
    setState(() => ({ ...userData, language: userData?.language }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUpdatedAt]);

  const options = [
    { label: "EN", value: "en" },
    { label: "GR", value: "el" },
  ];

  return (
    <>
      {contextHolder}
      <Layout key={userUpdatedAt}>
        <div className={styles.container}>
          <div className={styles.title}>
            <div className="HeadlineH2DarkGreyBold">{t("settings.title")}</div>
          </div>
          {!!error && <span className={styles.error}>{error?.error}</span>}
          <div className={styles.inputsWrapper}>
            <div className={styles.inputTitle}>{t("settings.firstName")}</div>
            <Input
              name="firstName"
              onChange={onChange}
              value={state?.firstName}
              onBlur={onBlur}
              size="large"
              className={styles.input}
            />
          </div>
          <div className={styles.inputsWrapper}>
            <div className={styles.inputTitle}>{t("settings.lastName")}</div>
            <Input
              name="lastName"
              onChange={onChange}
              value={state?.lastName}
              onBlur={onBlur}
              size="large"
              className={styles.input}
            />
          </div>
          <div className={styles.inputsWrapper}>
            <div className={styles.inputTitle}>{t("settings.phoneNumber")}</div>
            <Input
              name="phoneNumber"
              onChange={onChange}
              value={state?.phoneNumber}
              onBlur={onBlur}
              size="large"
              className={styles.input}
            />
          </div>
          {/* <div className={styles.inputsWrapper}>
            <div className={styles.inputTitle}>New Password</div>
            <Input
              name="password"
              onChange={onChange}
              value={state?.password}
              onBlur={onBlur}
              size="large"
              className={styles.input}
            />
          </div> */}
          <div className={styles.inputsWrapper}>
            <div className={styles.inputTitle}>{t("settings.language")}</div>
            <Select
              options={options}
              value={state?.language}
              onChange={onSelectChange}
              size="large"
              className={styles.input}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    width: 100%;
    @media (max-width: 768px) {
      margin-top: 4rem;
    }
  `,
  title: css`
    display: flex;
    margin-bottom: 0.5rem;
  `,
  inputsWrapper: css`
    margin-top: 1.5rem;
  `,
  inputTitle: css`
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  `,
  input: css`
    max-width: 400px;
  `,
  error: css`
    color: var(--red);
  `,
};
