import React from "react";
import {
  Button,
  DatePicker,
  DatePickerProps,
  Input,
  Modal,
  Select,
} from "antd";
import dayjs from "dayjs";
import { FamilyRole, Member } from "models/family";
import { css, cx } from "@emotion/css";
import { useI18n } from "providers/I18n";

const roleOptions = (t: (key: string) => string) => [
  { value: "parent", label: t("family.parent") },
  { value: "child", label: t("family.child") },
  { value: "relative", label: t("family.relative") },
  { value: "caregiver", label: t("family.caregiver") },
  { value: "nanny", label: t("family.nanny") },
  { value: "other", label: t("family.other") },
];

type Props = {
  open: boolean;
  onClose: VoidFunction;
  create: (member: Member) => void;
  update: (member: Partial<Member>) => void;
  isLoading?: boolean;
  error?: any;
  member?: Member;
};

const CreateMember = (props: Props) => {
  const { t } = useI18n();
  const { open, onClose, create, update, isLoading, error, member } = props;
  const [state, setState] = React.useState<Member>({});

  const handleOk = () => {
    !!member ? update(state) : create(state);
  };

  const handleCancel = () => {
    onClose();
  };

  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt?.target ?? {};
    setState((s) => ({ ...s, [name]: value }));
  };

  const onDateChange: DatePickerProps["onChange"] = (_, dateString) => {
    setState((s) => ({ ...s, birthdayAt: dateString as string }));
  };

  const onSelectChange = (value: string) => {
    setState((s) => ({ ...s, familyRole: value as FamilyRole }));
  };

  React.useEffect(() => {
    !!member && setState(member);
  }, [member]);

  return (
    <Modal
      title={
        <div className={styles.header}>
          {!!member ? t("family.updateMember") : t("family.addNewMember")}
        </div>
      }
      onOk={handleOk}
      open={open}
      onCancel={handleCancel}
      className={styles.modal}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {t("button.cancel")}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={handleOk}
          disabled={!state?.firstname || !state?.lastname || !state?.familyRole}
        >
          {!!member ? t("button.update") : t("button.create")}
        </Button>,
      ]}
    >
      <div>
        <div className={cx("Body1DarkGreySmall", styles.inputLabel)}>
          {t("firstName")}
        </div>
        <Input
          name="firstname"
          onChange={onChange}
          value={state?.firstname}
          size="large"
          placeholder={t("firstName")}
          className={styles.input}
        />
      </div>
      <div>
        <div className={cx("Body1DarkGreySmall", styles.inputLabel)}>
          {t("lastName")}
        </div>
        <Input
          name="lastname"
          onChange={onChange}
          value={state?.lastname}
          size="large"
          placeholder={t("lastName")}
          className={styles.input}
        />
      </div>
      <div>
        <div className={cx("Body1DarkGreySmall", styles.inputLabel)}>
          {t("familyRole")}
        </div>
        <Select
          options={roleOptions(t)}
          value={state?.familyRole}
          onChange={onSelectChange}
          className={cx(styles.select, styles.input)}
          size="large"
          placeholder={t("familyRole")}
        />
      </div>
      {state?.familyRole !== "child" ? (
        <div>
          <div className={cx("Body1DarkGreySmall", styles.inputLabel)}>
            {t("email")}
          </div>
          <Input
            name="email"
            onChange={onChange}
            value={state?.email}
            size="large"
            placeholder={t("email")}
            className={styles.input}
          />
        </div>
      ) : null}
      <div>
        <div className={cx("Body1DarkGreySmall", styles.inputLabel)}>
          {`${t("birthday")} (${t("optional")})`}
        </div>
        <DatePicker
          onChange={onDateChange}
          placeholder={t("birthday")}
          size="large"
          value={!!state?.birthdayAt ? dayjs(state?.birthdayAt) : null}
          className={cx(styles.select, styles.input)}
          suffixIcon={null}
        />
      </div>
      {!!error && <span className={styles.error}>{error?.error}</span>}
    </Modal>
  );
};

export default CreateMember;

const styles = {
  modal: css`
    border-radius: 10px;
    .ant-modal-content {
      padding: 0;
    }
    .ant-modal-header {
      .ant-modal-title {
        font-size: 1.4rem !important;
        font-weight: 400;
        padding: 2rem 2rem !important;
        background-color: aliceblue;
        border-radius: 10px;
      }
    }
    .ant-modal-body {
      padding: 2rem 2rem 0rem;
    }
    .ant-modal-footer {
      padding: 1rem;
    }
  `,
  header: css`
    margin-bottom: 0.5rem;
  `,
  inputLabel: css`
    margin-bottom: 0.5rem;
    margin-left: 0.25rem;
  `,
  input: css`
    margin-bottom: 1rem;
  `,
  select: css`
    width: 100%;
  `,
  error: css`
    color: var(--red);
  `,
};
