import * as React from "react";

import { css } from "@emotion/css";

import { CloseOutlined } from "@ant-design/icons";
import { useI18n } from "providers/I18n";
import { Button, Popover } from "antd";
import { ReactComponent as FiltersIcon } from "assets/filters.svg";

export type FilterProps = {
  children?: React.ReactNode;
};

export function Filters(props: FilterProps) {
  const { t } = useI18n();
  const [open, setOpen] = React.useState<boolean>(false);
  const onOpen = (open: boolean) => {
    setOpen(open);
  };
  const onClose = () => setOpen(false);

  return (
    <Popover
      trigger="click"
      // title={t("filters")}
      content={<FiltersContent onClose={onClose} {...props} />}
      open={open}
      onOpenChange={onOpen}
      placement="bottomLeft"
      className={styles.popover}
    >
      <Button
        type="primary"
        size="large"
        icon={<FiltersIcon />}
        className={styles.filtersBtn}
      >
        {/* {t("filters")} */}
      </Button>
    </Popover>
  );
}

export type FiltersContentProps = {
  children?: React.ReactNode;
  onClose?: VoidFunction;
};

const FiltersContent = (props: FiltersContentProps) => {
  const { children, onClose } = props;

  return (
    <div className={styles.container}>
      <div className={styles.titleBar}>
        <CloseOutlined className={styles.closeIcon} onClick={onClose} />
      </div>
      <div className={styles.filters}>{children}</div>
    </div>
  );
};

const styles = {
  popover: css`
    .ant-popover {
      padding: 1.5rem;
    }
  `,
  container: css`
    width: 100%;
  `,
  titleBar: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  filtersBtn: css`
    display: flex;
    align-items: center;
    padding: 7px 22px !important;
  `,
  filters: css`
    display: flex;
    overflow-y: auto;
    & > :not(:first-child) {
      margin-left: var(--spacing-xl);
    }
  `,
  btnLabel: css`
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.6;
    text-transform: capitalize;
  `,
  closeIcon: css`
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    curson: pointer;
  `,
};
