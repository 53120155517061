import React from "react";
import { Button, Col, Input, Menu, Row, message } from "antd";
import { CalendarOutlined, MailOutlined } from "@ant-design/icons";
import { css, cx } from "@emotion/css";
import dayjs from "dayjs";
import { groupBy } from "lodash/fp";
import {
  createFamily,
  createMember,
  deleteMember,
  updateFamily,
  updateMember,
} from "api/family";
import { Member } from "models/family";
import { useMutation, useQueryClient } from "react-query";
import { Layout } from "../AuthLanding/Layout";
import ConfirmationModal, { ModalInfo } from "components/ConfirmationModal";
import CreateMember from "./CreateMember";
import { useFamilyCachedData, useUserCachedData } from "../utils";
import { useI18n } from "providers/I18n";

export const MyFamily = () => {
  const { t } = useI18n();
  const [messageApi, contextHolder] = message.useMessage();
  const queryClient = useQueryClient();

  const [error, setError] = React.useState<any>();
  const [modalError, setModalError] = React.useState<any>();
  const [state, setState] = React.useState<{
    familyName: string;
  }>({ familyName: "" });
  const [member, setMember] = React.useState<Member | undefined>();
  const [open, setOpen] = React.useState<boolean>(false);
  const [modalInfo, setModalInfo] = React.useState<ModalInfo>(undefined);

  const { family, familyUpdatedAt, invalidateFamilyCache } =
    useFamilyCachedData();
  const { userData, userUpdatedAt, invalidateUserCache } = useUserCachedData();

  const { mutate: create } = useMutation(createFamily, {
    onSuccess: (r) => {
      messageApi.open({
        type: "success",
        content: r?.message,
      });
      invalidateFamilyCache();
      queryClient.invalidateQueries("user");
    },
    onError: (err: Error) => {
      const _err = JSON.parse(err.message);
      setError(_err);
      messageApi.open({
        type: "error",
        content: _err?.message,
      });
    },
  });

  const { mutate: update } = useMutation(updateFamily, {
    onSuccess: (r) => {
      invalidateFamilyCache();
      invalidateUserCache();
      messageApi.open({
        type: "success",
        content: r?.message,
      });
    },
    onError: (err: Error) => {
      const _err = JSON.parse(err.message);
      setError(_err);
      messageApi.open({
        type: "error",
        content: _err?.message,
      });
    },
  });

  const { mutate: _createMember } = useMutation(createMember, {
    onSuccess: (r) => {
      invalidateFamilyCache();
      onClose();
      messageApi.open({
        type: "success",
        content: r?.message,
      });
    },
    onError: (err: Error) => {
      const _err = JSON.parse(err.message);
      setModalError(_err);
      messageApi.open({
        type: "error",
        content: _err?.message,
      });
    },
  });

  const { mutate: _updateMember } = useMutation(updateMember, {
    onSuccess: (r) => {
      invalidateFamilyCache();
      invalidateUserCache();
      onClose();
      messageApi.open({
        type: "success",
        content: r?.message,
      });
    },
    onError: (err: Error) => {
      const _err = JSON.parse(err.message);
      setModalError(_err);
      messageApi.open({
        type: "error",
        content: _err?.message,
      });
    },
  });

  const { mutate: _deleteMember, isLoading: isDeleting } = useMutation(
    deleteMember,
    {
      onSuccess: (r) => {
        setModalInfo(undefined);
        invalidateFamilyCache();
        messageApi.open({
          type: "success",
          content: r?.message,
        });
      },
      onError: (err: Error) => {
        setModalInfo(undefined);
        const _err = JSON.parse(err.message);
        setError(_err);
        messageApi.open({
          type: "error",
          content: _err?.message,
        });
      },
    }
  );

  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt?.target ?? {};
    setError(undefined);
    setState((s) => ({ ...s, [name]: value }));
  };

  const onBlur = () => {
    !userData?.family
      ? create({ name: state.familyName })
      : update({ id: family?.id, name: state?.familyName });
  };

  const onDelete = (id: string) => {
    setModalInfo({
      title: t('familyMember.deleteTitle'),
      type: "primary",
      danger: true,
      description: t('familyMember.deleteConfirmation'),
      submitLabel: "delete",
      isSubmitDisabled: isDeleting,
      cancelLabel: "cancel",
      onSubmit: async () => await _deleteMember(id),
    });
  };

  const onOpen = (id?: string) => {
    setOpen(true);
    !!id && setMember(() => family?.members?.find((d) => d?.id === id));
  };
  const onClose = () => {
    setOpen(false);
    setMember(undefined);
  };

  React.useEffect(() => {
    setState(() => ({
      familyName: userData?.family?.name ?? "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUpdatedAt]);

  const availableGuardianRoles = React.useMemo(
    () =>
      groupBy(
        "familyRole",
        family?.members?.filter((m) => m?.familyRole !== "child") ?? []
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [familyUpdatedAt]
  );

  const availableChildRoles = React.useMemo(
    () => family?.members?.filter((m) => m?.familyRole === "child") ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [familyUpdatedAt]
  );
  const isModalOpen = Boolean(modalInfo);

  const items = (id?: string) => [
    {
      key: 1,
      label: t("menu.edit"),
      onClick: () => onOpen(id),
    },
    { key: 2, label: t("menu.delete"), onClick: () => !!id && onDelete(id) },
  ];

  const getName = (firstName?: string, lastName?: string) => {
    const f = !!firstName ? `${firstName} ` : "";
    const l = !!lastName ? lastName : "";
    return f + l;
  };

  return (
    <>
      {contextHolder}
      <Layout>
        <div className={styles.container}>
          <div className={styles.title}>
            <div className="HeadlineH2DarkGreyBold">{t("family.title")}</div>
            <Button type="primary" disabled={!family} onClick={() => onOpen()}>
              {`+ ${t("family.addNewMember")}`}
            </Button>
          </div>
          <span>{t("family.description")}</span>
          {!!error && <span className={styles.error}>{error?.error}</span>}
          <div className={styles.inputsWrapper}>
            <div className={styles.sectionWrapper}>
              <div className={styles.sectionTitle}>{t("family.name")}</div>
              <Col xs={12}>
                <Input
                  name="familyName"
                  onChange={onChange}
                  value={state?.familyName}
                  onBlur={onBlur}
                  size="large"
                  className={styles.familyInput}
                />
              </Col>
            </div>
            {!!family?.members && (
              <div className={styles.sectionWrapper}>
                <div className={styles.sectionTitle}>{t("family.members")}</div>
                <Row gutter={16}>
                  <Col
                    xs={{ span: 24, order: 2 }}
                    sm={{ span: 12, order: 1 }}
                    className="gutter-row"
                  >
                    {Object.keys(availableGuardianRoles)?.map((r, ridx) => (
                      <React.Fragment key={ridx}>
                        <div className={styles.guardianRoleTitle}>
                          {t(`family.${r}`)}
                        </div>
                        {availableGuardianRoles?.[r]?.map((g, gidx) => {
                          return (
                            <div className={styles.card} key={gidx}>
                              <div className={styles.cardSection}>
                                <div className={styles.firstWrapper}>
                                  <div className={styles.doctorName}>
                                    <div className="HeadlineH4DarkGreyBold">
                                      {getName(g?.firstname, g?.lastname)}
                                    </div>
                                  </div>
                                  <Menu
                                    mode="horizontal"
                                    items={items(g?.id)}
                                    className={styles.menu}
                                  />
                                </div>
                              </div>
                              <div className={styles.cardSection}>
                                <div className={styles.iconWrapper}>
                                  <CalendarOutlined className={styles.icon} />:
                                  <span
                                    className={cx(
                                      "Body1DarkGreySmall",
                                      styles.details
                                    )}
                                  >
                                    {!!g?.birthdayAt
                                      ? dayjs(g?.birthdayAt ?? "").format(
                                          "YYYY-MM-DD"
                                        )
                                      : ""}
                                  </span>
                                </div>
                                <div className={styles.iconWrapper}>
                                  <MailOutlined className={styles.icon} />:
                                  <span
                                    className={cx(
                                      "Body1DarkGreySmall",
                                      styles.details
                                    )}
                                  >
                                    {g?.email}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </React.Fragment>
                    ))}
                  </Col>
                  <Col
                    xs={{ span: 24, order: 1 }}
                    sm={{ span: 12, order: 2 }}
                    className="gutter-row"
                  >
                    <>
                      <div className={styles.guardianRoleTitle}>
                        {t("family.children")}
                      </div>
                      {availableChildRoles?.map((c, cidx) => {
                        return (
                          <div className={styles.card} key={cidx}>
                            <div className={styles.cardSection}>
                              <div className={styles.firstWrapper}>
                                <div className={styles.doctorName}>
                                  <div className="HeadlineH4DarkGreyBold">
                                    {getName(c?.firstname, c?.lastname)}
                                  </div>
                                </div>
                                <Menu
                                  mode="horizontal"
                                  items={items(c?.id)}
                                  className={styles.menu}
                                />
                              </div>
                            </div>
                            <div className={styles.cardSection}>
                              <div className={styles.iconWrapper}>
                                <CalendarOutlined className={styles.icon} />:
                                <span
                                  className={cx(
                                    "Body1DarkGreySmall",
                                    styles.details
                                  )}
                                >
                                  {!!c?.birthdayAt
                                    ? dayjs(c?.birthdayAt ?? "").format(
                                        "YYYY-MM-DD"
                                      )
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>
      </Layout>
      {!!open && (
        <CreateMember
          open={open}
          onClose={onClose}
          create={_createMember}
          update={_updateMember}
          error={modalError}
          member={member}
        />
      )}
      <ConfirmationModal
        open={isModalOpen}
        onClose={() => setModalInfo(undefined)}
        {...modalInfo}
      />
    </>
  );
};

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    width: 100%;
    @media (max-width: 768px) {
      margin-top: 4rem;
    }
  `,
  title: css`
    display: flex;
    justify-content: space-between;
    align-items: center; /* Ensures the title and button are vertically aligned */
    margin-bottom: 0.5rem;
    width: 100%; /* Ensures it takes full width */
  `,
  familyInput: css`
    font-size: 20px;
  `,
  inputsWrapper: css`
    margin-top: 3.5rem;
  `,
  sectionWrapper: css`
    margin-bottom: 4rem;
  `,
  sectionTitle: css`
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--light-grey);
  `,
  error: css`
    color: var(--red);
  `,
  card: css`
    background-color: var(--white);
    padding: 0.5rem 1rem;
    border-left: 5px solid var(--primary-main);
    border-radius: 0.375rem;
    margin-bottom: 1rem;
    min-height: 125px;
    @media (max-width: 600px) {
      padding: 0.5rem;
      min-height: 100px;
      padding-top: 0;
    }
  `,
  cardSection: css`
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0.5rem 1rem;
    &:not(:last-child) {
      border-bottom: 1px solid var(--grey);
    }
    &:not(:first-child) {
      padding: 1rem 0.5rem 1rem;
    }
    @media (max-width: 600px) {
      padding: 0.5rem;
      padding-right: 0;
    }
  `,
  firstWrapper: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  doctorName: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  menu: css`
    min-width: 0;
    justify-content: center;
    border: none;
    transform: rotate(90deg);
    width: fit-content;
    height: fit-content;
    align-items: center;
  `,
  iconWrapper: css`
    display: flex;
    align-items: center;
  `,
  icon: css`
    width: 14px;
    margin-right: 2px;
  `,
  lineHeight: css`
    line-height: 24px;
  `,
  details: css`
    margin-left: 8px;
  `,
  guardianRoleTitle: css`
    font-size: 1.2rem;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: var(--dark-grey);
    margin-bottom: 0.5rem;
  `
};

