import {
  HealthEvents,
  MedicineUnits,
  Metric,
  MetricDelete,
  MetricsResponse,
  MetricsState,
} from "models/healthEvent";
import { http } from "./http";

export const fetchHealthMetricTypes = async () => {
  const res = await http.get("health-metric-types").json();
  return res as Metric[];
};

export const fetchMedicationUnits = async () => {
  const res = await http.get("medicine/units").json();
  return res as MedicineUnits[];
};

export const fetchHealthEvents = async (params?: string) => {
  const res = await http.get("health-events", { searchParams: params }).json();
  return res as HealthEvents;
};

export const createHealthEvent = async (props: MetricsState) => {
  const res = await http.post("health-events", { json: props }).json();
  return res as MetricsResponse;
};

export const updateHealthEvent = async (pld: Partial<MetricsState>) => {
  const { id, ...event } = pld;
  const res = await http.patch(`health-events/${id}`, { json: event }).json();
  return res as HealthEvents;
};

export const deleteHealthEvent = async (id: string) => {
  const res = await http.delete(`health-events/${id}`).json();
  return res as MetricDelete;
};
