import { http } from "./http";
import { UserData, UserResponse } from "models/user";

export const fetchUser = async () => {
  const res = await http.get("user").json();
  return res as UserData;
};

export const updateUser = async (pld: Partial<UserData>) => {
  const res = await http.patch(`user`, { json: pld }).json();
  return res as UserResponse;
};
