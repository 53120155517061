import React from "react";
import { css, cx } from "@emotion/css";
import { Alert, Button, Card, Input, Layout } from "antd";
import { Header } from "components/Page";
import { register } from "providers/A12n";
import { useNavigate } from "react-router-dom";
import { Loading } from "components/Loading";
import { useI18n } from "providers/I18n";

export default function Register() {
  const { t } = useI18n();
  const navigate = useNavigate();
  const [values, setValues] = React.useState<{
    email: string;
    password: string;
    invitationCode?: string;
  }>({ email: "", password: "" });
  const [error, setError] = React.useState<Error | undefined>();
  const [pending, setPending] = React.useState<boolean>(false);

  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt?.target ?? {};
    setError(undefined);
    setValues((s) => ({ ...s, [name]: value }));
  };

  const onSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setPending(true);
    setError(undefined);
    register(values)
      .then((r) => {
        setPending(false);
        localStorage.setItem("snack", JSON.stringify(r?.message));
        navigate("/");
      })
      .catch((err) => {
        const _err = JSON.parse(err.message);
        setError(_err);
        setPending(false);
      });
  };

  return (
    <Layout className={styles.layout}>
      <Header />
      {pending ? (
        <Loading />
      ) : (
        <div className={styles.container}>
          <div className={cx("HeadlineH2DarkGreyBold", styles.title)}>
            {t("signup")}
          </div>
          {!!error ? (
            <Alert
              message={error.message}
              type="error"
              className={styles.errorAlert}
            />
          ) : null}
          <Card className={styles.card}>
            <form
              onSubmit={onSubmit}
              autoComplete="off"
              className={styles.form}
              noValidate
            >
              <div className={styles.inputWrapper}>
                <div className={cx("Body1DarkGreyRegular", styles.label)}>
                  {t("email")}
                </div>
                <Input
                  name="email"
                  onChange={onChange}
                  value={values?.email}
                  size="large"
                />
              </div>
              <div className={styles.inputWrapper}>
                <div className={cx("Body1DarkGreyRegular", styles.label)}>
                  {t("password")}
                </div>
                <Input
                  name="password"
                  onChange={onChange}
                  value={values?.password}
                  type="password"
                  size="large"
                />
              </div>
              <div className={styles.inputWrapper}>
                <div className={cx("Body1DarkGreyRegular", styles.label)}>
                  {t("invitationCode")}
                </div>
                <Input
                  name="invitationCode"
                  onChange={onChange}
                  value={values?.invitationCode}
                  size="large"
                />
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  disabled={
                    pending ||
                    !values?.email?.trim() ||
                    !values?.password?.trim() ||
                    !values?.invitationCode?.trim()
                  }
                >
                  {t("signup")}
                </Button>
              </div>
            </form>
          </Card>
        </div>
      )}
    </Layout>
  );
}

const styles = {
  layout: css`
    background-color: var(--light-grey-2);
  `,
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 64px);
  `,
  title: css`
    margin-bottom: 1.5rem;
  `,
  card: css`
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    max-width: 90%;
    width: 500px;
    align-items: center;
    border-radius: 0.375rem;
    border: 1px solid var(--grey);
    .ant-card-body {
      width: 100%;
      padding: 2rem;
    }
  `,
  form: css`
    width: 100%;
  `,
  inputWrapper: css`
    margin-bottom: 1rem;
  `,
  label: css`
    margin-bottom: 0.5rem;
  `,
  buttonWrapper: css`
    text-align: center;
    margin-top: 1.5rem;
  `,
  errorAlert: css`
    max-width: 90%;
    width: 500px;
    margin-bottom: 1rem;
  `,
};
