import { Row, Col } from "antd";
import { css, cx } from "@emotion/css";
import { useI18n } from "providers/I18n";

const links = (t: (key: string) => string) => [
  {
    title: t("footer.features.title"),
    sublinks: [t("footer.features.howItWorks"), t("footer.features.pricing")],
  },
  {
    title: t("footer.resources.title"),
    sublinks: [
      t("footer.resources.helpCenter"),
      t("footer.resources.support"),
      t("footer.resources.guide"),
    ],
  },
  {
    title: t("footer.company"),
    sublinks: [t("footer.about"), t("footer.contactUs")],
  },
];

const LinksSection = () => {
  const { t } = useI18n();
  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <Row align="middle" gutter={{ xs: 24, lg: 16 }} className={styles.row}>
          <Col xs={24} sm={20} lg={6} className="gutter-row">
            <div className={cx("HeadlineH1WhiteBold", styles.title)}>
              Hevetra
            </div>
            <div className={cx("Body1WhiteSmall", styles.body)}>
              {t("footer.desc")}
            </div>
          </Col>
          {links(t)?.map((l, lidx) => (
            <Col xs={8} lg={6} className="gutter-row" key={lidx}>
              <div className={styles.innerCol}>
                <div>
                  <div
                    className={cx("HeadlineH6WhiteBold", styles.linksHeader)}
                  >
                    {l?.title}
                  </div>
                  {l?.sublinks.map((s, sidx) => (
                    <div
                      className={cx("Body1WhiteSmall", styles.link)}
                      key={sidx}
                    >
                      {s}
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default LinksSection;

const styles = {
  container: css`
    margin-top: 100px;
  `,
  section: css`
    padding: 3rem;
    background-color: var(--primary-light);
  `,
  row: css`
    align-items: flex-start;
    justify-content: center;
  `,
  innerCol: css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  title: css`
    @media (max-width: 600px) {
      text-align: center;
    }
  `,
  body: css`
    margin: 1rem 0;
    @media (max-width: 600px) {
      text-align: center;
    }
  `,
  linksHeader: css`
    line-height: 4;
    @media (max-width: 600px) {
      font-size: 0.8rem;
      text-align: center;
    }
  `,
  link: css`
    line-height: 2;
    @media (max-width: 600px) {
      font-size: 0.75rem;
      text-align: center;
    }
    @media (max-width: 385px) {
      font-size: 0.625rem;
      text-align: center;
    }
  `,
};
