import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import "dayjs/locale/el";
import { NoUndefinedRangeValueType } from "rc-picker/lib/PickerInput/RangePicker";
import {
  HealthEvents,
  IntakesState,
  MetricState,
  MetricsState,
  SymptomState,
  VisitsState,
} from "models/healthEvent";
import ReportTableHor from "./ReportTableHor";
import { Family } from "models/family";
import ReportTable from "./ReportTable";
import RobotoRegular from "assets/fonts/Roboto-Regular.ttf";
import RobotoBold from "assets/fonts/Roboto-Bold.ttf";

const generateData = (
  event: keyof MetricsState,
  healthEvents: MetricsState[]
) => {
  return healthEvents
    ?.filter((e) => !!e?.[event]?.length)
    ?.flatMap((e) =>
      ((e?.[event] ?? []) as any[])?.map((o) => ({
        ...o,
        eventTime: e?.eventTime,
      }))
    );
};

type Props = {
  data?: HealthEvents;
  child?: string;
  family?: Family;
  dateRange?: NoUndefinedRangeValueType<dayjs.Dayjs> | undefined;
  t: (key: string) => string;
  language: string;
};

export function ExportDocument(props: Props) {
  Font.register({
    family: "Roboto",
    fonts: [
      {
        src: RobotoRegular,
        fontWeight: 400,
      },
      {
        src: RobotoBold,
        fontWeight: 700,
      },
    ],
  });

  const { data, child, family, dateRange, t, language } = props;

  const member = family?.members?.find((c) => c?.id === child);

  const rows = [
    {
      title: t("family.name"),
      Cell: (
        <Text>
          {member?.lastname} {member?.firstname}
        </Text>
      ),
    },
    {
      title: t("birthday"),
      Cell: (
        <Text>
          {dayjs(member?.birthdayAt).locale(language).format("D MMMM YYYY")}
        </Text>
      ),
    },
  ];

  const healthEvents = Object.values(data?.data ?? {})?.reduce(
    (acc, evt) => [...acc, ...evt],
    []
  );

  const symptomOccur = generateData("symptomOccurrences", healthEvents);
  const columnsOccur: (keyof (SymptomState & {
    eventTime: string;
  }))[] = ["type", "severityTitle", "notes"];
  const headersOccur = columnsOccur?.map(
    (c) => `healthEvent.symptomOccurrence.${c}`
  );

  const medications = generateData("medicationIntakes", healthEvents);
  const columnsMeds: (keyof (IntakesState & {
    eventTime: string;
  }))[] = ["medicineName", "dosage", "dosageUnitTitle", "comment"];
  const headersMeds = columnsMeds?.map(
    (c) => `healthEvent.medicationIntake.${c}`
  );

  const metrics = generateData("metrics", healthEvents);
  const columnsMetrics: (keyof (MetricState & {
    eventTime: string;
  }))[] = ["typeShortTitle", "typeTitle", "value", "unitTitle"];
  const headersMetrics = columnsMetrics?.map(
    (c) => `healthEvent.healthMetric.${c}`
  );

  const doctors = generateData("doctorVisits", healthEvents);
  const columnsDoctors: (keyof (VisitsState & {
    eventTime: string;
  }))[] = ["doctorName", "diagnosis", "notes"];
  const headersDoctors = columnsDoctors?.map(
    (c) => `healthEvent.doctorVisit.${c}`
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.body}>
          <View style={styles.titleWrapper}>
            <Text style={styles.title}>{t("healthReport.title")}</Text>
            <Text style={styles.subtitle}>
              {t("healthReport.for")} {`${member?.firstname}`}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>
              {t("healthReport.patienceInfo")}
            </Text>
          </View>
          <ReportTableHor rows={rows} />
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>{t("healthReport.summary")}</Text>
            <Text style={styles.dateInfo}>
              {t("healthEvent.dateRange.title")}:{" "}
              {`${dayjs(dateRange?.[0])
                .locale(language)
                .format("D MMMM YYYY")} - ${dayjs(dateRange?.[1])
                .locale(language)
                .format("D MMMM YYYY")}`}
            </Text>
          </View>
          {symptomOccur?.length ? (
            <View style={styles.section} wrap={false}>
              <Text style={styles.sectionTitle}>
                {t("healthEvent.symptomOccurrence.title")}
              </Text>
              <ReportTable
                headers={headersOccur}
                data={symptomOccur}
                columns={columnsOccur}
                t={t}
              />
            </View>
          ) : null}
          {medications?.length ? (
            <View style={styles.section} wrap={false}>
              <Text style={styles.sectionTitle}>
                {t("healthEvent.medicationIntake.title")}
              </Text>
              <ReportTable
                headers={headersMeds}
                data={medications}
                columns={columnsMeds}
                t={t}
              />
            </View>
          ) : null}
          {metrics?.length ? (
            <View style={styles.section} wrap={false}>
              <Text style={styles.sectionTitle}>
                {t("healthEvent.healthMetric.title")}
              </Text>
              <ReportTable
                headers={headersMetrics}
                data={metrics}
                columns={columnsMetrics}
                t={t}
              />
            </View>
          ) : null}
          {doctors?.length ? (
            <View style={styles.section} wrap={false}>
              <Text style={styles.sectionTitle}>
                {t("healthEvent.doctorVisit.title")}
              </Text>
              <ReportTable
                headers={headersDoctors}
                data={doctors}
                columns={columnsDoctors}
                t={t}
              />
            </View>
          ) : null}
        </View>
        <View fixed style={styles.footer}>
          <div style={styles.footerContent}>
            <Text style={styles.website}>hevetra.com</Text>
          </div>
        </View>
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    padding: "0 50px",
    fontFamily: "Roboto",
    fontSize: 10,
  },
  body: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "65px",
    height: "100%",
  },
  titleWrapper: {
    margin: "50px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontWeight: 700,
    fontSize: 24,
  },
  subtitle: {
    fontSize: 16,
  },
  section: {
    display: "flex",
    flexDirection: "column",
  },
  sectionTitle: {
    margin: "24px 0 4px",
    fontWeight: 700,
  },
  dateInfo: {
    fontSize: "8px",
  },
  row: {
    display: "flex",
    gridColumnGap: "1rem",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    width: "64px",
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 0,
    right: 0,
  },
  footerContent: {
    margin: "0 50px",
    borderTop: "1px solid #eeeeee",
    paddingTop: 8,
  },
  website: {
    fontWeight: 700,
    textAlign: "right",
  },
});
