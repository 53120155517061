import React from "react";
import { css, cx } from "@emotion/css";
import { Button, Col, Row, Select } from "antd";
import { NumericInput } from "components/Inputs";
import { Metric, MetricState, MetricsState } from "models/healthEvent";
import { CloseOutlined } from "@ant-design/icons";
import { useI18n } from "providers/I18n";

type Props = {
  metrics?: Metric[];
  metricsUpdatedAt?: number;
  state: MetricsState;
  onChange: (path: string, value: string | MetricState | MetricState[]) => void;
};

export const HealthMetric = (props: Props) => {
  const { t } = useI18n();
  const { metrics, metricsUpdatedAt, state, onChange } = props;

  const options = React.useMemo(
    () =>
      metrics?.map((m) => ({
        label: `${m?.shortTitle} - ${m?.title}`,
        value: m?.id,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [metricsUpdatedAt]
  );

  const unitOptions = (id: string) =>
    metrics
      ?.find((m) => m?.id === id)
      ?.units?.map((u) => ({ label: u?.unit, value: u?.id }));

  const onNumericChange = (value: string, name: string, idx: number) => {
    onChange(`metrics.${idx}.${name}`, value);
  };

  const onSelectChange = (
    value: string,
    _:
      | {
          label: string;
          value: string;
        }
      | {
          label: string;
          value: string;
        }[],
    name: string,
    idx: number
  ) => {
    onChange(`metrics.${idx}.${name}`, value);
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => !!option && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const onAdd = () => {
    const metricsLength = state?.metrics?.length;
    onChange(`metrics.${metricsLength}`, {
      typeId: "",
      value: "",
      unitId: "",
    });
  };

  const onDelete = (idx: number) => {
    const _metrics =
      state?.metrics?.filter((m) => state?.metrics?.indexOf(m) !== idx) ?? [];
    onChange("metrics", _metrics);
  };

  return (
    <div className={styles.section}>
      {state?.metrics?.map((s, sidx) => (
        <Row className={styles.row} gutter={8} key={sidx}>
          <Col xs={24} sm={12} className={styles.col}>
            <div className={cx("Body1DarkGreySmall", styles.eventTitle)}>
              {t("healthEvent.healthMetric.metric")}
            </div>
            <Select
              showSearch
              allowClear
              options={options}
              value={s?.typeId}
              onChange={(value, option) =>
                onSelectChange(value, option, "typeId", sidx)
              }
              optionFilterProp="children"
              filterOption={filterOption}
              size="middle"
              className={styles.input}
            />
          </Col>
          <Col xs={12} sm={6} className={styles.col}>
            <div className={cx("Body1DarkGreySmall", styles.eventTitle)}>
              {t("healthEvent.healthMetric.value")}
            </div>
            <NumericInput
              name="value"
              onChange={(v) => onNumericChange(v, "value", sidx)}
              value={String(s?.value)}
              size="middle"
              className={styles.input}
            />
          </Col>
          <Col xs={12} sm={6} className={styles.col}>
            <div className={cx("Body1DarkGreySmall", styles.eventTitle)}>
              {t("healthEvent.healthMetric.unit")}
            </div>
            <Select
              options={unitOptions(s?.typeId)}
              value={s?.unitId}
              onChange={(value, option) =>
                onSelectChange(value, option, "unitId", sidx)
              }
              size="middle"
              className={styles.input}
            />
          </Col>
          <CloseOutlined
            className={styles.closeIcon}
            onClick={() => onDelete(sidx)}
          />
        </Row>
      ))}
      <Row className={styles.buttonRow}>
        <Button
          type="primary"
          size="middle"
          className={styles.button}
          onClick={onAdd}
        >
          +
        </Button>
      </Row>
    </div>
  );
};

const styles = {
  section: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  row: css`
    display: flex;
    align-items: flex-start;
    padding: 0.75rem;
    border: 1px solid var(--primary-main);
    border-radius: 8px;
    width: 100%;
    margin-bottom: 0.5rem;
    position: relative;
  `,
  col: css`
    margin-bottom: 0.25rem;
  `,
  buttonRow: css`
    display: flex;
    align-items: flex-start;
    padding: 0.5rem;
  `,
  button: css`
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    padding-bottom: 2px;
    display: flex;
    justify-content: center;
  `,
  input: css`
    width: 100%;
  `,
  eventTitle: css`
    margin-bottom: 0.25rem;
  `,
  closeIcon: css`
    position: absolute;
    top: 6px;
    right: 6px;
    curson: pointer;
  `,
};
