import { Button, Layout as AntLayout, Dropdown, MenuProps } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import MediaQuery from "react-responsive";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { useNavigate } from "react-router-dom";
import LanguageSwitch from "components/LanguageSwitch";
import { useI18n } from "providers/I18n";

const { Header: AntHeader, Footer: AntFooter } = AntLayout;

export const Header = () => {
  const { t } = useI18n();
  const navigate = useNavigate();

  const handleLoginClick = () => navigate("/login");
  const handleRegisterClick = () => navigate("/register");

  const items: MenuProps["items"] = [
    {
      key: 1,
      label: t("login"),
      onClick: handleLoginClick,
      className: styles.item,
    },
    {
      key: 2,
      label: t("landingPage.startNow"),
      onClick: handleRegisterClick,
      className: styles.item,
    },
  ];

  return (
    <AntHeader className={styles.header}>
      <div className={styles.logo} onClick={() => navigate("/")}>
        <div className={styles.logo_svg}>
          <Logo />
        </div>
        <div className={styles.logo_text}>Hevetra</div>
      </div>
      <div className={styles.buttons}>
        <LanguageSwitch />
        <MediaQuery minWidth={768}>
          <Button
            type="text"
            onClick={handleLoginClick}
            className={styles.textButton}
          >
            {t("login")}
          </Button>
          <Button type="primary" onClick={handleRegisterClick}>
            {t("landingPage.startNow")}
          </Button>
        </MediaQuery>
        <MediaQuery maxWidth={768}>
          <Dropdown menu={{ items }} trigger={["click"]}>
            {<UserOutlined className={styles.loginIcon} />}
          </Dropdown>
        </MediaQuery>
      </div>
    </AntHeader>
  );
};

export const Footer = () => (
  <AntFooter className={styles.footer}>
    Hevetra ©{new Date().getFullYear()} - v0.46
  </AntFooter>
);

const styles = {
  header: css`
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
    padding: 0 calc(1.5rem * 0.5);
    @media (min-width: 768px) {
      padding: 0 50px;
    }
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  `,
  logo: css`
    display: flex;
    align-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  `,
  logo_svg: css`
    margin-top: 18px;
    width: 32px;
    height: auto;
  `,
  logo_text: css`
    font-size: 1.3rem;
    line-height: 2rem;
    font-weight: 300;
  `,
  buttons: css`
    display: flex;
    align-items: center;
    column-gap: 8px;
    @media (max-width: 768px) {
      column-gap: 4px;
    }
  `,
  textButton: css`
    &.ant-btn {
      padding: 4px 15px;
      @media (max-width: 768px) {
        padding: 4px 6px;
      }
    }
  `,
  loginIcon: css`
    padding: 0 0.825rem;
  `,
  item: css`
    text-align: center;
  `,
  footer: css`
    margin-top: 2rem;
    background-color: var(--white);
    text-align: center;
    padding: 0.6rem 1rem;
  `,
};
