import React from "react";
import { css } from "@emotion/css";
import { Col, Layout, message } from "antd";
import { Footer, Header } from "components/Page";
import HeroSection from "./Sections/HeroSection";
import ServicesSection from "./Sections/ServicesSection";
import EmpoweringSection from "./Sections/EmpoweringSection";
import LinksSection from "./Sections/LinksSection";

export const Landing = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const snack = localStorage.getItem("snack");

  React.useEffect(() => {
    if (!!snack) {
      messageApi.open({
        type: "success",
        content: snack,
      });

      localStorage.removeItem("snack");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {contextHolder}
      <Layout className={styles.layout}>
        <Header />
        <div className={styles.landing}>
          <Col xs={24} md={22} lg={20}>
            <div className={styles.sections}>
              <HeroSection />
              <ServicesSection />
              <EmpoweringSection />
              <LinksSection />
            </div>
          </Col>
        </div>
        <Footer />
      </Layout>
    </>
  );
};

const styles = {
  layout: css`
    background-color: var(--light-grey-2);
  `,
  landing: css`
    display: flex;
    justify-content: center;
    padding: 0 calc(1.5rem * 0.5);
  `,
  sections: css`
    display: flex;
    flex-direction: column;
  `,
};
