import React from "react";
import { AuthProvider } from "providers/A12n";
import { Loading } from "components/Loading";
import { Route, Routes, useNavigate } from "react-router-dom";
import { MyFamily } from "./Family";
import { AuthorizedRoute } from "components/AuthorizedRoute";
import { AuthLanding } from "./AuthLanding";
import { Landing } from "routes/Landing";
import { Doctors } from "./Doctors";
import { Timeline } from "./Timeline";
import { Settings } from "./Settings";
import { Admin } from "./Admin";

export default function Main() {
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = React.useState<boolean>();

  React.useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
      navigate("/");
    }
  }, [navigate]);

  const landingElem = !!authenticated ? (
    <AuthorizedRoute>
      <AuthLanding />
    </AuthorizedRoute>
  ) : (
    <Landing />
  );
  return (
    <AuthProvider>
      <React.Suspense fallback={<Loading />}>
        <Routes>
          <Route
            path="/family"
            element={
              <AuthorizedRoute>
                <MyFamily />
              </AuthorizedRoute>
            }
          />
          <Route
            path="/doctors"
            element={
              <AuthorizedRoute>
                <Doctors />
              </AuthorizedRoute>
            }
          />
          <Route
            path="/timeline"
            element={
              <AuthorizedRoute>
                <Timeline />
              </AuthorizedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <AuthorizedRoute>
                <Settings />
              </AuthorizedRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <AuthorizedRoute>
                <Admin />
              </AuthorizedRoute>
            }
          />
          <Route path="/" element={landingElem} />
        </Routes>
      </React.Suspense>
    </AuthProvider>
  );
}
