import ky from "ky";
import { afterResHooks, beforeResHooks } from "./hooks";

export const http = ky.extend({
  throwHttpErrors: true,
  retry: 2,
  prefixUrl: process.env.REACT_APP_BASE_API_URL,
  hooks: {
    beforeRequest: [beforeResHooks.setAuthHeader],
    afterResponse: [
      afterResHooks.handleEmpty,
      afterResHooks.handleUnauth,
      afterResHooks.handleErrors,
    ],
  },
});
