import React from "react";
import { verify } from "providers/A12n";
import { useLocation, useNavigate } from "react-router-dom";

export const Verify = () => {
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    if (token) {
      verify(token)
        .then((r) => {
          localStorage.setItem("snack", JSON.stringify(r?.message));
          navigate("/");
        })
        .catch((err) => {
          localStorage.setItem("snack", JSON.stringify(err?.message));
          navigate("/");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
