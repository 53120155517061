import { Row, Col, Button } from "antd";
import { css, cx } from "@emotion/css";
import HeroHeader from "assets/hero-header.png";
import { useNavigate } from "react-router-dom";
import { useI18n } from "providers/I18n";

const HeroSection = () => {
  const { t } = useI18n();
  const navigate = useNavigate();

  const handleRegisterClick = () => navigate("/register");

  return (
    <div className={styles.container}>
      <Row align="middle" gutter={{ xs: 24 }}>
        <Col sm={24} md={{ span: 12, order: 1 }} className="gutter-row">
          <div className={styles.img_wrapper}>
            <img src={HeroHeader} alt="" width="100%" className={styles.img} />
          </div>
        </Col>
        <Col
          sm={24}
          md={12}
          className={cx(styles.text_wrapper, styles.text_center, "gutter-row")}
        >
          <Row
            className={cx(
              "HeadlineH1DarkGreyBold",
              styles.title,
              styles.text_center
            )}
          >
            {t("landingPage.title")}
          </Row>
          <Row
            className={cx(
              "HeadlineH3DarkGreyLight",
              styles.body,
              styles.text_center
            )}
          >
            {t("landingPage.subtitle")}
          </Row>
          <Button type="primary" size="large" onClick={handleRegisterClick}>
            {t("landingPage.startNow")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default HeroSection;

const styles = {
  container: css`
    margin-top: 100px;
  `,
  img_wrapper: css`
    display: flex;
    justify-content: center;
    padding: 0 calc(1.5rem * 0.5);
  `,
  img: css`
    max-width: 100%;
    height: "auto";
  `,
  text_wrapper: css`
    padding: 3rem calc(1.5rem * 0.5) 0;
  `,
  title: css`
    margin-bottom: 1.5rem;
  `,
  body: css`
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  `,
  text_center: css`
    text-align: center;
    @media (min-width: 768px) {
      text-align: left;
    }
  `,
};
