import React from "react";
import { Layout as AntLayout, MenuProps, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/css";
import {
  LogoutOutlined,
  SettingOutlined,
  IdcardOutlined,
  ClockCircleOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { ReactComponent as Logo } from "assets/logo.svg";
import { useAuth } from "providers/A12n";
import { useQueryClient } from "react-query";
import { useI18n } from "providers/I18n";
import { useUserCachedData } from "../utils";

const { Header: AntHeader } = AntLayout;

export const AuthHeader = () => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { logout } = useAuth();
  const { userData } = useUserCachedData();
  const { family, role } = userData ?? {};

  const adminItems: MenuProps["items"] = [
    {
      label: t("menu.admin"),
      key: "user:1",
      icon: <UserOutlined />,
      onClick: () => navigate("/admin"),
    },
  ];

  const mainItems: MenuProps["items"] = [
    // {
    //   label: "Dashboard",
    //   key: "user:1",
    //   icon: <HomeOutlined />,
    //   onClick: () => navigate("/dashboard"),
    // },
    {
      label: t("menu.timeline"),
      key: "user:2",
      icon: <ClockCircleOutlined />,
      onClick: () => navigate("/timeline"),
    },
    {
      label: t("menu.doctors"),
      key: "user:3",
      icon: <IdcardOutlined />,
      onClick: () => navigate("/doctors"),
    },
    {
      label: t("menu.family"),
      key: "user:4",
      icon: <TeamOutlined />,
      onClick: () => navigate("/family"),
    },
    {
      label: t("menu.settings"),
      key: "user:5",
      icon: <SettingOutlined />,
      onClick: () => navigate("/settings"),
    },
    {
      type: "divider",
    },
    {
      label: t("menu.logout"),
      key: "user:6",
      icon: <LogoutOutlined />,
      onClick: () => logout(),
    },
  ];

  const items = role === "admin" ? [...adminItems, ...mainItems] : mainItems;

  const buttons = !!family
    ? [
        {
          key: 1,
          label: `${family?.name}`,
          icon: <TeamOutlined />,
          onClick: () => navigate("/family"),
        },
        {
          key: 2,
          label: !!userData?.firstName
            ? `${t("hello")}, ${userData?.firstName}`
            : `${t("hello")}`,
          children: items,
        },
      ]
    : [
        {
          key: 1,
          label: !!userData?.firstName
            ? `${t("hello")}, ${userData?.firstName}`
            : `${t("hello")}`,
          children: items,
        },
      ];

  React.useEffect(() => {
    const handleInvalidation = (event: any) => {
      if (event.query.queryKey[0] === "user") {
      }
    };

    const unsubscribe = queryClient
      .getQueryCache()
      .subscribe(handleInvalidation);

    return () => {
      unsubscribe();
    };
  }, [queryClient]);

  return (
    <AntHeader className={styles.header}>
      <div className={styles.logo} onClick={() => navigate("/timeline")}>
        <div className={styles.logo_svg}>
          <Logo />
        </div>
        <div className={styles.logo_text}>Hevetra</div>
      </div>
      <Menu mode="horizontal" items={buttons} className={styles.menu} />
    </AntHeader>
  );
};

const styles = {
  header: css`
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
    padding: 0 1.5rem;
    @media (max-width: 385px) {
      padding: 0 0.5rem;
    }
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  `,
  logo: css`
    display: flex;
    align-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    height: 100%;
  `,
  logo_svg: css`
    margin-top: 18px;
    width: 32px;
    height: auto;
  `,
  logo_text: css`
    font-size: 1.3rem;
    line-height: 2rem;
    font-weight: 300;
  `,
  menu: css`
    flex: 1;
    min-width: 0;
    justify-content: flex-end;
    &.ant-menu-horizontal {
      border: none;
    }
  `,
};
