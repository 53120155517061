import { AfterResponseHook, BeforeRequestHook } from "ky";
import { getToken, logout } from "providers/A12n";

export const afterResHooks: Record<string, AfterResponseHook> = {
  handleEmpty: (_inpt, _opts, res) => {
    if (res.status === 204 || res.status === 202) {
      const o = JSON.stringify({});
      return new Response(o, { status: 200 });
    } else {
      return res;
    }
  },
  handleUnauth: async (_inpt, _opts, res) => {
    if (res.status === 401) {
      await logout();
      return;
    }
    if (res.status === 403) {
      await logout();
      return;
    }
    return res;
  },
  handleErrors: async (_input, _options, res) => {
    if (!res.ok) {
      const message = (await res?.text()) || "Unknown error occurred";
      const e = new Error(message);
      throw e;
    }
  },
};

export const beforeResHooks: Record<string, BeforeRequestHook> = {
  setAuthHeader: async (request) => {
    const accessToken = await getToken();
    if (accessToken) {
      request.headers.set("Authorization", "Bearer " + accessToken);
    }
  },
};
